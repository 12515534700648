import React from "react";
import PaymentMethods from "./PaymentOptions/PaymentMethods";

const MobilePayment = () => {
  return (
    <div>
      <PaymentMethods />
    </div>
  );
};

export default MobilePayment;
