import React from "react";
import "./FinalReview.css";
import FlightDetailsCard from "./FlightDetailsCard";
import ReviewPassengerDetails from "./ReviewPassengerDetails";
import {
  setFareBreakUpsPopUp,
  setJourneyProgress,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";
import { useDispatch } from "react-redux";
const FinalReview = () => {
  const dispatch = useDispatch();
  return (
    <div className="review-container">
      <FlightDetailsCard />
      <ReviewPassengerDetails />
      <div className="action-buttons">
        <button
          className="fare-button"
          onClick={() => dispatch(setFareBreakUpsPopUp(true))}
        >
          Fare Breakup
        </button>
        <button
          className="review-button"
          onClick={() => dispatch(setJourneyProgress(3))}
        >
          Proceed to Pay
        </button>
      </div>
    </div>
  );
};

export default FinalReview;
