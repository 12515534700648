import React from "react";
import styles from "./FlightItineary.module.css";
import FlightTabs from "./FlightDetails/FlightTabs";
import { FlightSummary } from "../PassengerDetails/components/FlightSummary";
import { useDispatch, useSelector } from "react-redux";
import { setJourneyProgress } from "../../../../features/CheckoutJourney/checkoutJourneySlice";
import { useNavigate } from "react-router-dom";
const FlightItineary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flight = useSelector((state) => state.checkoutJourney.bookingData);
  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.contentGrid}>
          <div className={styles.leftColumn}>
            <div className={styles.formContainer}>
              <FlightTabs flight={flight} />
              <div className={styles.actionButtons}>
                <div className="gap-x-[1rem] flex">
                  <button
                    type="button"
                    className={styles.backButton}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
                <button
                  type="submit"
                  className={styles.proceedButton}
                  onClick={() => dispatch(setJourneyProgress(1))}
                >
                  Proceed to Add Passenger Information
                </button>
              </div>
            </div>
          </div>
          <div className={styles.rightColumn}>
            <FlightSummary />
          </div>
        </div>
      </main>
    </div>
  );
};

export default FlightItineary;
