import React, { useEffect, useState } from "react";
import "./SeatMobile.css"; // Add your styles here
import SeatMatrixMobile from "./SeatMatrixMobile";
import SeatFlightMobile from "./SeatFlightMobile";
import { ChevronLeft, UserRound } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setFareBreakUpsPopUp } from "../../../../../../../features/CheckoutJourney/checkoutJourneySlice";

const SeatMobile = ({
  selectedSeat,
  onSettingPassengerSeat,
  backButtonActionToPrev,
}) => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const passengerId = useSelector(
    (state) => state.checkoutJourney.passengerIndex
  );
  const [rows, setRows] = useState([
    [
      { type: "ColNo", color: "#FFD7000000" },
      { type: "ColNo", color: "#FFD7000000" },
      { type: "ColNo", color: "#FFD7000000" },
      { empty: true },
      { type: "ColNo", color: "#FFD7000000" },
      { type: "ColNo", color: "#FFD7000000" },
      { type: "ColNo", color: "#FFD7000000" },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 1900 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 850 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 950 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 950 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
  ]);
  const selectingSeat = (rowIndex, seatIndex, seatNumber, seatPrice) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.map((seat) => ({ ...seat }))
      );
      for (let i = 0; i < updatedRows.length; i++) {
        for (let j = 0; j < updatedRows[i].length; j++) {
          if (updatedRows[i][j].selected === "selected") {
            updatedRows[i][j].selected = "";
          }
        }
      }
      updatedRows[rowIndex][seatIndex].selected = "selected";
      return updatedRows;
    });
    onSettingPassengerSeat(rowIndex, seatIndex, seatNumber, seatPrice);
  };

  useEffect(() => {
    // debugger;
    if (selectedSeat["rowIndex"]) {
      // debugger;
      const rowIndex = selectedSeat?.rowIndex;
      const seatIndex = selectedSeat?.seatIndex;
      setRows((prevRows) => {
        const updatedRows = prevRows.map((row) =>
          row.map((seat) => ({ ...seat }))
        );
        for (let i = 0; i < updatedRows.length; i++) {
          for (let j = 0; j < updatedRows[i].length; j++) {
            if (updatedRows[i][j].selected === "selected") {
              updatedRows[i][j].selected = "";
            }
          }
        }
        updatedRows[rowIndex][seatIndex].selected = "selected";
        return updatedRows;
      });
    }else {
      bookingData.passengerDetails.map((passenger, index) => {
        if (bookingData.passengerDetails[index]?.seat) {
          if (index === passengerId) {
            const rowIndex = bookingData.passengerDetails[index]?.seat?.rowIndex;
            const seatIndex =
              bookingData.passengerDetails[index]?.seat?.seatIndex;
            debugger;
            setRows((prevRows) => {
              const updatedRows = prevRows.map((row) =>
                row.map((seat) => ({ ...seat }))
              );
              for (let i = 0; i < updatedRows.length; i++) {
                for (let j = 0; j < updatedRows[i].length; j++) {
                  if (updatedRows[i][j].selected === "selected") {
                    updatedRows[i][j].selected = "unavailable";
                  }
                }
              }
              if (updatedRows[rowIndex][seatIndex].selected !== "selected") {
                updatedRows[rowIndex][seatIndex].selected = "selected";
              }

              return updatedRows;
            });
          } else {
          const rowIndex = bookingData.passengerDetails[index]?.seat?.rowIndex;
          const seatIndex =
            bookingData.passengerDetails[index]?.seat?.seatIndex;

          setRows((prevRows) => {
            const updatedRows = prevRows.map((row) =>
              row.map((seat) => ({ ...seat }))
            );
            for (let i = 0; i < updatedRows.length; i++) {
              for (let j = 0; j < updatedRows[i].length; j++) {
                if (updatedRows[i][j].selected === "selected") {
                  updatedRows[i][j].selected = "selected";
                }
              }
            }
            if (updatedRows[rowIndex][seatIndex].selected !== "selected") {
              updatedRows[rowIndex][seatIndex].selected = "unavailable";
            }
            return updatedRows;
          });
        }
        }
      });
    }
  }, []);

  return (
    <div className="seat-map-container">
      <div className="backBtn-wraper">
        <ChevronLeft onClick={() => backButtonActionToPrev()} />
        <h2 className="selector-title">Seat Selection</h2>
      </div>
      <SeatFlightMobile />

      <div className="seat-layout">
        <SeatMatrixMobile rows={rows} onSelectingSeat={selectingSeat} />
      </div>

      <div className="legend">
        <div className="legend-items">
          <span className="legend-dot selected"></span> Selected
        </div>
        <div className="legend-items">
          <span className="legend-dot available"></span> Available
        </div>
        <div className="legend-items">
          <span className="legend-dot not-available"></span> Not Available
        </div>
      </div>

      <div className="selection-info">
        {selectedSeat["seatNumber"] && (
          <div className="selected-seat">
            <h2>Selected Seat:</h2>
            <div className="selectedSeat-Wrapper">
              <span className="selected-seatNumber">
                <UserRound stroke={0} fill={"#fff"} size={14} />{" "}
                {selectedSeat?.seatNumber}
              </span>
              <span className="selected-seatPrice">
                Rs.{selectedSeat?.seatPrice}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="action-buttons">
        <button
          className="fare-button"
          onClick={() => dispatch(setFareBreakUpsPopUp(true))}
        >
          Fare Breakup
        </button>
        <button
          className="review-button"
          onClick={() => backButtonActionToPrev()}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default SeatMobile;
