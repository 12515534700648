const flights = [
  {
    FlightDetails: {
      Airline: {
        Name: "Air India",
        FlightNumber: "AI-205/77W",
        Logo: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7d9112cdaa5dac40aa08493310fd53a9bb84af176bf893de2f4559656c0b9a2?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
      },
      TotalStops: "Non-Stop",
      Class: "Economy",
      FlightType: "Domestic",
      Route: {
        Departure: {
          City: "Delhi",
          AirportCode: "DEL",
          Date: "4 Dec, Sat",
          Time: "06:45",
          Airport: "Indira Gandhi Intl Terminal 3",
        },
        Arrival: {
          City: "Manali",
          AirportCode: "KUU",
          Date: "6 Dec, Mon",
          Time: "08:10",
          Airport: "Bhuntar Airport Terminal 1",
        },
      },
      Duration: "1 hr 20 min",
      PriceOptions: [
        { price: 27076, fareType: "Ndc Economy Saver" },
        { price: 54088, fareType: "Ndc Economy Saver" },
        { price: 29000, fareType: "Ndc Economy Saver" },
      ],
      selectedPrice: null,
    },
    FareDetails: {
      BaseFare: 20000,
      TaxesAndFees: 5000,
      AirlineSurcharges: 2000,
      OptionalAddOns: {
        SeatSelection: 0,
        MealPreferences: 0,
        ExtraBaggage: 0,
      },
      Total: 27078,
    },
    BaggageInformation: {
      CarryOnBaggage: {
        WeightLimit: "Max 7 Kg",
        ItemsAllowed:
          "Check your baggage allowances and fees to ensure you travel light.",
        ProhibitedItems:
          "Check your baggage allowances and fees to ensure you travel light.",
      },
      CheckedBaggage: {
        Allowance: "Max 25 Kg",
        ExcessBaggageFees: "Max 25 Kg",
        ProhibitedItems: "Max 25 Kg",
      },
    },
    CancellationAndRefundPolicy: {
      FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
      After24Hours:
        "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
      RefundProcess: "Refunds are processed within 10-14 business days.",
      CancellationFees:
        "Fee of XX for cancellations within 7 days of departure.",
    },
  },
  {
    FlightDetails: {
      Airline: {
        Name: "Air India",
        FlightNumber: "AI-205/77W",
        Logo: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7d9112cdaa5dac40aa08493310fd53a9bb84af176bf893de2f4559656c0b9a2?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
      },
      TotalStops: "Non-Stop",
      Class: "Economy",
      FlightType: "Domestic",
      Route: {
        Departure: {
          City: "Delhi",
          AirportCode: "DEL",
          Date: "4 Dec, Sat",
          Time: "06:45",
          Airport: "Indira Gandhi Intl Terminal 3",
        },
        Arrival: {
          City: "Manali",
          AirportCode: "KUU",
          Date: "6 Dec, Mon",
          Time: "08:10",
          Airport: "Bhuntar Airport Terminal 1",
        },
      },
      Duration: "1 hr 20 min",
      PriceOptions: [
        { price: 27079, fareType: "Ndc Economy Saver" },
        { price: 35000, fareType: "Ndc Economy Saver" },
        { price: 37000, fareType: "Ndc Economy Saver" },
      ],
      selectedPrice: 2708,
    },
    FareDetails: {
      BaseFare: 20000,
      TaxesAndFees: 5000,
      AirlineSurcharges: 2000,
      OptionalAddOns: {
        SeatSelection: 0,
        MealPreferences: 0,
        ExtraBaggage: 0,
      },
      Total: 27078,
    },
    BaggageInformation: {
      CarryOnBaggage: {
        WeightLimit: "Max 7 Kg",
        ItemsAllowed:
          "Check your baggage allowances and fees to ensure you travel light.",
        ProhibitedItems:
          "Check your baggage allowances and fees to ensure you travel light.",
      },
      CheckedBaggage: {
        Allowance: "Max 25 Kg",
        ExcessBaggageFees: "Max 25 Kg",
        ProhibitedItems: "Max 25 Kg",
      },
    },
    CancellationAndRefundPolicy: {
      FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
      After24Hours:
        "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
      RefundProcess: "Refunds are processed within 10-14 business days.",
      CancellationFees:
        "Fee of XX for cancellations within 7 days of departure.",
    },
  },
  {
    FlightDetails: {
      Airline: {
        Name: "Air India",
        FlightNumber: "AI-205/77W",
        Logo: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7d9112cdaa5dac40aa08493310fd53a9bb84af176bf893de2f4559656c0b9a2?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
      },
      TotalStops: "Non-Stop",
      Class: "Economy",
      FlightType: "International",
      Route: {
        Departure: {
          City: "Delhi",
          AirportCode: "DEL",
          Date: "4 Dec, Sat",
          Time: "06:45",
          Airport: "Indira Gandhi Intl Terminal 3",
        },
        Arrival: {
          City: "Manali",
          AirportCode: "KUU",
          Date: "6 Dec, Mon",
          Time: "08:10",
          Airport: "Bhuntar Airport Terminal 1",
        },
      },
      Duration: "1 hr 20 min",
      PriceOptions: [
        { price: 27078, fareType: "Ndc Economy Saver" },
        { price: 34000, fareType: "Ndc Economy Saver" },
        { price: 38000, fareType: "Ndc Economy Saver" },
      ],
      selectedPrice: null,
    },
    FareDetails: {
      BaseFare: 20000,
      TaxesAndFees: 5000,
      AirlineSurcharges: 2000,
      OptionalAddOns: {
        SeatSelection: 0,
        MealPreferences: 0,
        ExtraBaggage: 0,
      },
      Total: 27078,
    },
    BaggageInformation: {
      CarryOnBaggage: {
        WeightLimit: "Max 7 Kg",
        ItemsAllowed:
          "Check your baggage allowances and fees to ensure you travel light.",
        ProhibitedItems:
          "Check your baggage allowances and fees to ensure you travel light.",
      },
      CheckedBaggage: {
        Allowance: "Max 25 Kg",
        ExcessBaggageFees: "Max 25 Kg",
        ProhibitedItems: "Max 25 Kg",
      },
    },
    CancellationAndRefundPolicy: {
      FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
      After24Hours:
        "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
      RefundProcess: "Refunds are processed within 10-14 business days.",
      CancellationFees:
        "Fee of XX for cancellations within 7 days of departure.",
    },
  },
];
export default flights;
