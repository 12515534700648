import React from "react";

const SeatChair = ({ seatselection }) => {
  return (
    <>
      {seatselection === "" && (
        <div className="cursor-pointer">
          <svg
            width="46"
            height="46"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M47.6943 47.4362C49.8125 46.9868 51.4727 45.1333 51.4727 42.8305L51.4727 18.9035C51.4727 16.6568 49.8697 14.7472 47.7515 14.2978L47.7515 4.74949C47.6943 2.67133 46.0341 0.986328 43.9159 0.986328L8.59389 0.986326C6.47571 0.986326 4.81552 2.67133 4.81552 4.74949L4.81552 14.2417C2.58285 14.6348 0.922656 16.5445 0.922656 18.8473L0.922655 42.8305C0.922655 44.9648 2.35385 46.7622 4.24304 47.3238C4.47203 49.6828 6.47571 51.5363 8.93738 51.5363L43.0572 51.5363C45.4044 51.5363 47.408 49.739 47.6943 47.4362ZM49.8125 18.9035L49.8125 42.8867C49.8125 44.2908 48.8965 45.4142 47.637 45.8073C47.1791 43.9538 45.6334 42.5497 43.7442 42.2688L43.7442 18.9035C43.7442 17.2185 45.1181 15.8705 46.7783 15.8705C48.4385 15.8705 49.8125 17.2185 49.8125 18.9035ZM6.47571 14.2978L6.47571 4.74949C6.47571 3.56999 7.44893 2.61516 8.59389 2.61516L43.9159 2.61516C45.1181 2.61516 46.0341 3.56999 46.0341 4.74949L46.0341 14.2417C43.7442 14.5787 42.0267 16.5445 42.0267 18.8473L42.0267 42.1003L10.2541 42.1003L10.2541 18.9035C10.3113 16.6007 8.65114 14.691 6.47571 14.2978ZM2.5256 42.8305L2.5256 18.9035C2.5256 17.2185 3.89955 15.8705 5.55975 15.8705C7.21994 15.8705 8.59389 17.2185 8.59389 18.9035L8.59389 42.2127C6.53296 42.325 4.87277 43.7853 4.35754 45.695C3.32707 45.1895 2.5256 44.1223 2.5256 42.8305ZM8.93738 49.9075C7.27719 49.9075 5.90323 48.5595 5.90323 46.8745C5.90323 45.1895 7.27719 43.8415 8.93738 43.8415L43.0572 43.8415C44.7174 43.8415 46.0913 45.1895 46.0913 46.8745C46.0913 48.5595 44.7174 49.9075 43.0572 49.9075L8.93738 49.9075Z"
              fill="#FE6F61"
            />
          </svg>
        </div>
      )}
      {seatselection === "unavailable" && (
        <div>
          <svg
            width="46"
            height="46"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M47.0341 46.7565C49.1523 46.3071 50.8125 44.4536 50.8125 42.1508L50.8125 18.2238C50.8125 15.9771 49.2096 14.0675 47.0914 13.6181L47.0914 4.06981C47.0341 1.99164 45.3739 0.30664 43.2558 0.30664L7.93373 0.306639C5.81555 0.306639 4.15536 1.99164 4.15536 4.06981L4.15536 13.562C1.92269 13.9551 0.2625 15.8648 0.2625 18.1676L0.262499 42.1508C0.262499 44.2851 1.6937 46.0825 3.58288 46.6441C3.81187 49.0031 5.81556 50.8566 8.27722 50.8566L42.397 50.8566C44.7442 50.8566 46.7479 49.0593 47.0341 46.7565Z"
              fill="#ADADAD"
            />
          </svg>
        </div>
      )}
      {seatselection === "selected" && (
        <div className="cursor-pointer">
          <svg
            width="46"
            height="46"
            viewBox="0 0 51 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M47.1474 47.0045C49.2656 46.5552 50.9258 44.7017 50.9258 42.3989L50.9258 18.4719C50.9258 16.2252 49.3228 14.3155 47.2047 13.8662L47.2047 4.31785C47.1474 2.23969 45.4872 0.554687 43.369 0.554687L8.04701 0.554686C5.92883 0.554686 4.26865 2.23969 4.26865 4.31785L4.26865 13.81C2.03597 14.2032 0.375781 16.1129 0.375781 18.4157L0.37578 42.3989C0.37578 44.5332 1.80698 46.3305 3.69616 46.8922C3.92515 49.2512 5.92884 51.1047 8.3905 51.1047L42.5103 51.1047C44.8575 51.1047 46.8612 49.3073 47.1474 47.0045Z"
              fill="#FE6F61"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default SeatChair;
