import React from "react";
import styles from "../PaymentForm.module.css";
import { Check } from "lucide-react";

export const SecurityMessage = ({ icon, text }) => {
  return (
    <div className={styles.securityMessage}>
      <Check color="green" />
      <div className={styles.securityText}>{text}</div>
    </div>
  );
};
