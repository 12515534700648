import React from "react";
import { HotelImageGallery } from "../../hotel_components/Banner/HotelDetailBanner/Hotelgallery/HotelImageGallery";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import { RoomFeatures } from "./components/roomFeatures/RoomFeatures";
import { RoomFacilities } from "./components/roomFacilities/RoomFacilities";
import RoomTestimonials from "./components/testimonials/RoomTestimonials";
import { ReviewGallery } from "./components/ReviewGallery/ReviewGallery";
import useWindowSize from "../../../Hooks/useWindowSize";
import RoomHeader from "../../[Mobile]/room-detail/RoomHeader/RoomHeader";
import { HotelTimeInfo } from "../../[Mobile]/hotel-detail/hotel-descriptions/HotelDescriptions";
import { RoomCard } from "../../[Mobile]/room-detail/roomCard/RoomCard";
import { RoomdeptBookingDetails } from "../../[Mobile]/room-detail/roomindept-detail/RoomdeptBookingDetails";
import { BookingReview } from "../../[Mobile]/bookingReview/BookingReview";
const RoomDetailPage = () => {
  const size = useWindowSize();
  return (
    <div>
      <NavbarNew />
      {size.width > 469 ? <HotelImageGallery /> : <RoomHeader />}

      <RoomFeatures />
      <RoomFacilities />
      {size.width < 469 && (
        <>
          <HotelTimeInfo />
          <br />
          <RoomCard />
        </>
      )}
      {/* <RoomdeptBookingDetails /> */}
      <RoomTestimonials />
      <ReviewGallery />
    </div>
  );
};

export default RoomDetailPage;
