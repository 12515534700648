import React from "react";

const HotelHeader = () => {
  return (
    <div>
      <div className="border-2 relative rounded-[5rem] overflow-hidden w-full h-[76rem]">
        <img
          className="absolute w-full h-[100%] object-cover"
          src="https://s3-alpha-sig.figma.com/img/2de1/63ec/5551d917bcb7d8364f947e1d31ded7a0?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V1tkST49WpIJuMiUC2pIK~IWLKNFfXWUClQrMGPUcf2x5DAYgSZc3LNBBsZGLlaVaVTxZlRT6seIpMTs5kaq9ADzDl0mb6v8vtEcssRvRad4RT-xFlcLH7zNzUAp491o~fFrTecS~922JvNUvO1a4GHze0WYZwdjq5YtmlR7C7uyG0bUUp~~O9ukBCQYOu3lEzvcvkBe408~82fz9V7~iGpYU0kwwcqL5Ivl-1o5OuY6jOIBjduZgHwryRjBxKe7aWc-DlYR9FF~7GrTOoLlPvc10PQJXufUcxqpFPjttykEJ-Bx8hGCz~0ZWSjqW-xdFa8Bd577kzqYm3498X7Y6w__"
          alt=""
        />
        <div className="relative top-[65rem] left-[2rem] text-[5rem] flex gap-5 bottom-0 text-white">
          <p>Resort of kings</p>
          <p>⭐️ 3.9</p>
        </div>
      </div>
    </div>
  );
};

export default HotelHeader;
