import * as React from "react";
import "./PaymentFlightCard.css";
import FlightIcon from "../../../../../Assets/flightspage/greyFlight.svg";
import FlightLocation from "./FlightLocation.jsx";
import { useSelector } from "react-redux";
const PaymentFlightCard = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const flightData = bookingData.FlightDetails;
  const passengerData = bookingData.passengerDetails;

  return (
    <>
      <div className="payment-flight-review-container">
        <div className="payment-flight-card">
          <div className="payment-flight-header">
            <div className="payment-review-title">Flight Review</div>
            <div className="payment-flight-details">
              <FlightLocation
                date={flightData?.Route.Departure.Date}
                city={flightData?.Route?.Departure.City}
              />
              <div className="payment-flight-duration">
                <div className="payment-flight-type">
                  {flightData?.TotalStops}
                </div>
                <div className="flightwithLine">
                  <div className="payment-flight-line" />
                  <img
                    loading="lazy"
                    src={FlightIcon}
                    alt="Flight direction indicator"
                    className="payment-flight-icon"
                  />
                </div>
                <div>{flightData?.Duration}</div>
              </div>
              <div className="payment-destination-info">
                <FlightLocation
                  date={flightData?.Route?.Arrival?.Date}
                  city={flightData?.Route?.Arrival?.City}
                />
              </div>
            </div>
          </div>
          <div className="payment-divider" />
          <div className="payment-passenger-section">
            <div className="payment-passenger-title">Passenger(s)</div>
            {passengerData.map((passenger, index) => (
              <div key={index}>
                <div className="payment-passenger-name">
                  <span className="payment-bold">
                    {passenger?.firstName} {passenger?.middleName}{" "}
                    {passenger?.lastName}
                  </span>
                  {passenger?.gender && passenger?.gender}
                </div>
                <div className="payment-passenger-contact">
                  {passenger?.email} | {passenger?.mobileNo}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentFlightCard;
