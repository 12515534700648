import { createSlice } from "@reduxjs/toolkit";

const checkoutJourney = createSlice({
  name: "CheckoutJourney",
  initialState: {
    seatData: null,
    baggageSeat: {
      seatPopUp: false,
      passengerIndex: null,
    },
    journeyProgress: 0,
    bookingData: {
      passengerDetails: [],
      FareDetails: {
        BaseFare: 0,
        TaxesAndFees: 0,
        AirlineSurcharges: 0,
        OptionalAddOns: {
          SeatSelection: 0,
          MealPreferences: 0,
          ExtraBaggage: 0,
        },
        Total: 0,
      },
    },
    addPassenger: false,
    passengerIndex: null,
    fareBreakups: false,
  },
  reducers: {
    setSeatData: (state, action) => {
      state.data = action.payload;
    },
    setBaggageSeat: (state, action) => {
      state.baggageSeat = action.payload;
    },
    setJourneyProgress: (state, action) => {
      state.journeyProgress = action.payload;
    },
    setBookingData: (state, action) => {
      state.bookingData = action.payload;
    },
    updatePassengerDetails: (state, action) => {
      const { passengerId, updatedDetails } = action.payload;

      const passengerIndex = state.bookingData.passengerDetails.findIndex(
        (p) => p.id === passengerId
      );
      if (passengerIndex !== -1) {
        state.bookingData.passengerDetails[passengerIndex] = {
          ...state.bookingData.passengerDetails[passengerIndex],
          ...updatedDetails,
        };
      } else {
        state.bookingData.passengerDetails.push({
          id: passengerId,
          ...updatedDetails,
        });
      }
    },
    deletePassenger: (state, action) => {
      const { passengerId } = action.payload;
      const passengerIndex = state.bookingData.passengerDetails.findIndex(
        (p) => p.id === passengerId
      );
      if (passengerIndex >= 0) {
        // debugger;
        state.bookingData.passengerDetails.splice(passengerIndex, 1);
      }
    },
    setPassengerIndex: (state, action) => {
      state.passengerIndex = action.payload;
    },
    setAddNewPassenger(state, action) {
      state.bookingData.passengerDetails.push(action.payload);
    },
    setAddPassanger: (state, action) => {
      state.addPassenger = action.payload;
    },
    setFareBreakUpsPopUp: (state, action) => {
      state.fareBreakups = action.payload;
    },
    setSeatPrice: (state, action) => {
      const { seatPrice, Action } = action.payload;
      const fareDetails = state.bookingData.FareDetails;
      if (Action === "add")
        fareDetails.OptionalAddOns.SeatSelection += parseAmount(seatPrice);
      else if (Action === "remove")
        fareDetails.OptionalAddOns.SeatSelection -= parseAmount(seatPrice);
    },
    setBaggagePrice: (state, action) => {
      const { baggagePrice, Action } = action.payload;
      if (Action === "add") {
        const fareDetails = state.bookingData.FareDetails;
        fareDetails.OptionalAddOns.ExtraBaggage += parseAmount(baggagePrice);
      } else if (Action === "remove") {
        const fareDetails = state.bookingData.FareDetails;
        fareDetails.OptionalAddOns.ExtraBaggage -= parseAmount(baggagePrice);
      }
    },
    setMealPrice: (state, action) => {
      const { mealPrice, Action } = action.payload;
      if (Action === "add") {
        const fareDetails = state.bookingData.FareDetails;
        fareDetails.OptionalAddOns.MealPreferences += parseAmount(mealPrice);
      } else if (Action === "remove") {
        const fareDetails = state.bookingData.FareDetails;
        fareDetails.OptionalAddOns.MealPreferences -= parseAmount(mealPrice);
      }
    },
  },
});

function parseAmount(amount) {
  if (typeof amount === "string") {
    return parseInt(amount.replace(/,/g, "").replace("-", ""), 10) || 0;
  }
  return amount || 0;
}

export const {
  setSeatData,
  setBaggageSeat,
  setJourneyProgress,
  setBookingData,
  setAddPassanger,
  setAddNewPassenger,
  setFareBreakUpsPopUp,
  updatePassengerDetails,
  setPassengerIndex,
  setBaggagePrice,
  setMealPrice,
  setSeatPrice,
  deletePassenger,
} = checkoutJourney.actions;

export default checkoutJourney.reducer;
