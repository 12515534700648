import React from "react";
import styles from "./PaymentForm.module.css";
import { SecurityMessage } from "./components/SecurityMessage";
import { FormField } from "./components/FormField";
import { DateSelect } from "./components/DateSelect";

export const PaymentForm = () => {
  return (
    <form className={styles.paymentContainer}>
      <div className={styles.paymentWrapper}>
        <div className={styles.headerSection}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.paymentTitle}>Payment method</h1>
            <SecurityMessage text="We use secure transmission" />
          </div>
          <div className={styles.protectionMessage}>
            <SecurityMessage text="We Protect your personal information" />
          </div>
        </div>

        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa6ab9ce0b78143f43789844f9e56cb4df4ccaa2771da1e7ee0edf1a5275ca09?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
          className={styles.cardIcon}
          alt="Credit card icon"
        />

        <FormField label="Name on Card" id="nameOnCard" />

        <FormField
          label="Debit/Credit Card number"
          id="cardNumber"
          type="number"
        />

        <label className={styles.formLabel}>Expiry date</label>
        <div className={styles.dateContainer}>
          <DateSelect label="Month" />
          <DateSelect label="Year" />
        </div>

        <label htmlFor="securityCode" className={styles.formLabel}>
          Security Code
        </label>
        <input
          type="number"
          id="securityCode"
          className={styles.securityCodeInput}
          aria-label="Security Code"
        />

        <label htmlFor="country" className={styles.formLabel}>
          Billing country/territory
        </label>
        <div className={styles.countrySelect}>
          <div>India</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5338d3874a4c3ddb3dfe2ec6c98159996190e2caa11e3f21b1e9d97832c72e8c?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
            className={styles.dropdownIcon}
            alt=""
          />
        </div>
        <button className={styles.checkoutbtn}>Check OUt</button>
      </div>
    </form>
  );
};
