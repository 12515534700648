import React from "react";
import { AirlineInfo } from "../FlightItineary/FlightDetails/FlightDetails/AirlineInfo";
import styles from "./FlightDetailsCard.module.css";
import { FlightTimeInfo } from "../FlightItineary/FlightDetails/FlightDetails/FlightTimeInfo";
import greyFlight from '../../../../Assets/flightspage/greyFlight.svg'
import { useSelector } from "react-redux";

const FlightDetailsCard = () => {
  const flightDetails = useSelector((state) => state.checkoutJourney.bookingData).FlightDetails
  return (
    <div className={styles.flightCard}>
      <div className={styles.container}>
        <div className={styles.flightHeader}>
          <AirlineInfo
            logoSrc={flightDetails?.Airline?.Logo}
            airlineName={flightDetails?.Airline?.Name}
            flightNumber={flightDetails?.Airline?.FlightNumber}
          />
          <div className={styles.contentWrapper}>
            <FlightTimeInfo
              date={flightDetails?.Route?.Departure?.Date}
              time={flightDetails?.Route?.Departure?.Time}
              location={flightDetails?.Route?.Departure?.City}
              airport={flightDetails?.Route?.Departure?.Airport}
            />
            <div className={styles.durationColumn}>
              <div className={styles.durationWrapper}>
                <div className={styles.durationLine}>
                  <div className={styles.line} />
                  <div className={styles.duration}>
                    {flightDetails?.Duration}
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={greyFlight}
                  className={styles.durationIcon}
                  alt=""
                />
              </div>
            </div>
            <div className={styles.arrivalColumn}>
              <div className={styles.arrivalInfo}>
                <div className={styles.arrivalDateTime}>
                  <div className={styles.arrivalDate}>
                    {flightDetails?.Route?.Arrival?.Date}
                  </div>
                  <div>{flightDetails?.Route?.Arrival?.Time}</div>
                </div>
                <div className={styles.airport}>
                  {flightDetails?.Route?.Arrival?.AirportCode}
                </div>
                <div>{flightDetails?.Route?.Arrival?.Airport}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default FlightDetailsCard;
