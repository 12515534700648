import React from "react";

const FareItem = ({ label, amount, type }) => {
  const labelClassName =
    type === "header" ? "header-item" : type === "subitem" ? "sub-item" : "";

  const amountClassName = `amount ${type === "header" ? "amount-header" : ""} 
    ${type === "discount" ? "amount-discount" : ""}`;

  return (
    <div className="fare-breakup-details">
      <div className={labelClassName}>{label}</div>
      <div className={amountClassName}>{amount}</div>
    </div>
  );
};
export default FareItem;
