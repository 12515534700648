import FlightInfo from "./FlightInfo";
import FlightTime from "./FlightTime";
import AirlineInfo from "./AirlineInfo";
import "./FlightCards.css";
import { useState } from "react";

const FlightCard = ({ flightData }) => {
  const [price, setPrice] = useState(
    flightData?.FlightDetails?.PriceOptions[0]
  );

  return (
    <>
      <div className="flight-card">
        <div className="flight-card-content">
          <span className="flight-type">
            {flightData?.FlightDetails?.TotalStops}
          </span>
          <FlightInfo
            origin={flightData?.FlightDetails?.Route?.Departure}
            destination={flightData?.FlightDetails.Route?.Arrival}
            duration={flightData?.FlightDetails.Duration}
          />
          <FlightTime
            departure={flightData?.FlightDetails.Route?.Departure}
            arrival={flightData?.FlightDetails?.Route?.Arrival}
          />
          <AirlineInfo
            airline={flightData?.FlightDetails?.Airline}
            price={price.price}
            flightData={flightData}
          />
        </div>
      </div>
    </>
  );
};
export default FlightCard;
