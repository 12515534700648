export default function FlightTime({ departure, arrival }) {
  return (
    <>
      <div className="time-container">
        <div className="time-block">
          <span className="time">{departure.Time}</span>
          <span className="date">{departure.Date}</span>
        </div>
        <div className="time-block arrival">
          <span className="time">{arrival.Time}</span>
          <span className="date">{arrival.Date}</span>
        </div>
      </div>
    </>
  );
}