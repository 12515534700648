import React, { useEffect, useState } from "react";
import styles from "./SeatMealSelection.module.css";
import Meal from "./Meal/Meal";
import Seat from "./Seat/Seat";
import Baggage from "./Baggage/Baggage";
import { useDispatch, useSelector } from "react-redux";
import { setBaggageSeat } from "../../../../features/CheckoutJourney/checkoutJourneySlice";

const SeatMealSelection = () => {
  const [activeTab, setActiveTab] = useState("meal");
  const dispatch = useDispatch();
  const showSeatSelection = useSelector(
    (state) => state.checkoutJourney.baggageSeat.seatPopUp
  );

  const closePopup = () => {
    dispatch(setBaggageSeat({ seatPopUp: false, passengerIndex: null }));
    setActiveTab("meal");
  };
  return (
    <>
      {showSeatSelection && (
        <div className={styles.container}>
          <div className={styles.crossBtn} onClick={closePopup}>
            X
          </div>
          <div className={styles.tabHeader}>
            <button
              className={activeTab === "meal" ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab("meal")}
            >
              Meal
            </button>
            <button
              className={activeTab === "seat" ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab("seat")}
            >
              Seat
            </button>
            <button
              className={
                activeTab === "baggage" ? styles.activeTab : styles.tab
              }
              onClick={() => setActiveTab("baggage")}
            >
              Baggage
            </button>
          </div>
          <div className={styles.content}>
            {activeTab === "meal" && <Meal />}
            {activeTab === "seat" && <Seat />}
            {activeTab === "baggage" && <Baggage />}
          </div>
        </div>
      )}
    </>
  );
};

export default SeatMealSelection;
