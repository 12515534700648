import React from "react";
import { RoomFeatureColumn } from "./RoomFeatureColumn";
import styles from "./RoomFeatures.module.css";
import useWindowSize from "../../../../../Hooks/useWindowSize";
import {
  BedSingle,
  Cigarette,
  Grid2x2,
  HdmiPort,
  MountainSnow,
  School,
  ShowerHead,
  Wifi,
} from "lucide-react";

const leftColumnFeatures = [
  {
    icon: <BedSingle />,
    text: "1 double bed",
  },
  {
    icon: <ShowerHead />,
    text: "Separate shower/bathtub",
  },
  {
    icon: <School />,
    text: "Room size:180ft",
  },
  {
    icon: <Wifi />,
    text: "Free Wi-Fi",
  },
  {
    icon: <HdmiPort />,
    text: "Balcony/terrace",
  },
  {
    icon: <Grid2x2 />,
    text: "Long windows",
  },
  {
    icon: <Cigarette />,
    text: "Smoking allowed",
  },
  {
    icon: <MountainSnow />,
    text: "Mountain view",
  },
];

export const RoomFeatures = () => {
  const size = useWindowSize();
  return (
    <div className={styles.container}>
      {size.width < 469 && (
        <p className="text-[4rem] font-bold m-9">
          Super Deluxe Room (with Balcony)
        </p>
      )}

      <div className={styles.wrapper}>
        <RoomFeatureColumn features={leftColumnFeatures} />
      </div>
    </div>
  );
};
