import { HotelFilterMobile } from "../SearchBar/HotelFilterMobile";
import { X } from "lucide-react";
import "../SearchBar/components/PriceSlider.css";
import React, { useState } from "react";
import styles from "./SearchFilters.module.css";
import { FilterButton } from "./FilterButton";
import { DateGuestInfo } from "./DateGuestInfo";
import { HotelListingCardMobile } from "../hotelCard/HotelListingCardMobile";
import { Drawer } from "@mui/material";
import DrawerContent from "../DrawerContent/DrawerContent";

export const HotelTopBarMobile = () => {
  const [showFilter, setShowFilter] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");

  const filters = [
    { label: "Price" },
    { label: "Location" },
    { label: "Star Rating" },
    { label: "Free Cancellation" },
  ];

  const toggleDrawer = (isOpen, filterLabel = "") => {
    setDrawerOpen(isOpen);
    setActiveFilter(filterLabel);
  };

  const getDrawerContent = () => {
    switch (activeFilter) {
      case "Price":
        return (
          <DrawerContent
            closeDrawer={() => toggleDrawer(false)}
            filterType={"pricerange"}
          />
        );
      case "Location":
        return (
          <DrawerContent
            closeDrawer={() => toggleDrawer(false)}
            filterType={"locationsearch"}
          />
        );
      case "Star Rating":
        return (
          <DrawerContent
            closeDrawer={() => toggleDrawer(false)}
            filterType={"starrating"}
          />
        );
      case "Free Cancellation":
        return <p>View hotels with free cancellation</p>;
      default:
        return null;
    }
  };

  return (
    <>
      {showFilter ? (
        <HotelFilterMobile setShowFilter={setShowFilter} />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.location}>Bali, Indonesia</div>
            <DateGuestInfo dates="24 OCT-26 OCT" guestCount={3} />
            <div className={styles.filterContainer}>
              <div onClick={() => setShowFilter(!showFilter)}>
                <FilterButton label="Filters" />
              </div>
              {filters.map((filter, index) => (
                <div
                  key={index}
                  onClick={() => toggleDrawer(true, filter.label)}
                >
                  <FilterButton label={filter.label} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {Array.from({ length: 9 }, (_, id) => (
              <HotelListingCardMobile key={id} />
            ))}
          </div>

          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <div className={styles.drawerContent}>
              <h2>
                <span />
                <p className="text-[5rem]">{activeFilter} </p>

                <div onClick={() => toggleDrawer(false)}>
                  <X color="red" />
                </div>
              </h2>
              {getDrawerContent()}
              <div
                onClick={() => toggleDrawer(false)}
                className={styles.result}
              >
                Show Result
              </div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};
