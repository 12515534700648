import React, { useState } from "react";
import styles from "./SeatMatrix.module.css";
import SeatChair from "./SeatChair";
import flightHeader from "../../../../../../Assets/flightHeader.svg";

const SeatMatrix = ({ rows, onSelectingSeat }) => {
  const [showToolTip, setShowTooltip] = useState({});
  const getSeatLabel = (rowIndex, seatIndex) => {
    // debugger
    let seatLabel;
    switch (seatIndex) {
      case 0:
        seatLabel = "A";
        break;
      case 1:
        seatLabel = "B";
        break;
      case 2:
        seatLabel = "C";
        break;
      case 4:
        seatLabel = "D";
        break;
      case 5:
        seatLabel = "E";
        break;
      case 6:
        seatLabel = "F";
        break;
      default:
        seatLabel = "";
        break;
    }
    const rowLabel = rowIndex + 1;
    return `${rowLabel}${seatLabel}`;
  };
  const selectingSeat = (rowIndex, seatIndex, seat) => {
    if (seat.selected === "unavailable")
      alert("seat is not available try with another Seat");
    else {
      const seatNumber = getSeatLabel(rowIndex, seatIndex);
      onSelectingSeat(rowIndex, seatIndex, seatNumber, seat.price);
      toolTipShow(seatIndex, rowIndex);
    }
  };
  const toolTipShow = (seatIndex, rowIndex, seat) => {
    console.log(seat);
    if (seat?.selected === "unavailable") return;
    setShowTooltip({ seatIndex, rowIndex });
  };
  return (
    <div className={styles.outerContainer}>
      <div className={styles.flightWrap}>
        <div className={styles.iconflFront}>
          <img src={flightHeader} alt="" />
        </div>
        <div className={styles.flightSeatMatrix}>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className={styles.seatRow}>
              {row.map((seat, seatIndex) => (
                <div
                  key={seatIndex}
                  className={`${styles.seatCol} ${
                    seat.empty ? styles.seatEmpty : ""
                  }`}
                  style={{ margin: "2.8px" }}
                >
                  {seat.empty ? (
                    <div className={styles.seatEmptyLabel}></div>
                  ) : (
                    <div
                      className={`${styles.seatBlock} ${
                        seat.type === "exit" ? styles.noReclineExitSeat : ""
                      }  cursor-pointer`}
                      style={{ backgroundColor: seat.color }}
                      onClick={() => selectingSeat(rowIndex, seatIndex, seat)}
                      onMouseEnter={() =>
                        toolTipShow(seatIndex, rowIndex, seat)
                      }
                      onMouseLeave={() => toolTipShow()}
                    >
                      <span
                        className={seat.selected !== "" ? "text-white" : ""}
                      >
                        {getSeatLabel(rowIndex, seatIndex)}
                      </span>
                      {(seat.type === "exit" || seat.type === "Seat") && (
                        <SeatChair seatselection={seat.selected} />
                      )}
                      {showToolTip.seatIndex === seatIndex &&
                        showToolTip.rowIndex === rowIndex && (
                          <div className={styles.seatToolTip}>
                            &#8377;{seat.price}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeatMatrix;
