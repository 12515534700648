import React from "react";
import { RoomFeature } from "./RoomFeature";
import styles from "./RoomBookingCard.module.css";
import { useNavigate } from "react-router-dom";

export const RoomBookingCard = () => {
  const features = ["No meals included", "Non-Refundable"];
  const navigate = useNavigate();
  return (
    //
    <div className={styles.roomCard}>
      <div className={styles.cardContainer}>
        <div className={styles.roomTitle}>Super Deluxe Room (with Balcony)</div>

        {features.map((feature, index) => (
          <RoomFeature key={index} text={feature} />
        ))}

        <div className={styles.priceContainer}>
          <div className={styles.mainPrice}>₹ 3,999</div>
          <div className={styles.priceLabel}>Per Night</div>
        </div>

        <div className={styles.discountContainer}>
          <div className={styles.discountPrice}>
            <div>₹1,999</div>
          </div>
          <div className={styles.taxInfo}>+ 499 takes</div>
        </div>

        <button
          onClick={() => navigate("/hotel-listing/rooms/asd")}
          className={styles.bookButton}
          tabIndex={0}
        >
          BOOK THIS NOW
        </button>
      </div>
    </div>
  );
};
