import React from "react";
import styles from "./PriceDetails.module.css";

const priceBreakdown = [
  { label: "1 room x 2 nights", value: 2000 },
  { label: "Taxes and fees", value: 1000 },
  { label: "Local tax", value: 999 },
];

const paymentOptions = [
  { label: "Pay now", value: 3999 },
  { label: "Pay at property", value: 1000 },
];

export function PriceDetails() {
  return (
    <div className={styles.priceCard}>
      <h2 className={styles.title}>Price details</h2>
      <div className={styles.divider} />

      {priceBreakdown.map((item, index) => (
        <div key={`price-${index}`} className={styles.priceRow}>
          <span className={styles.priceLabel}>{item.label}</span>
          <div className={styles.priceValue}>
            ₹<span>{item.value}</span>
          </div>
        </div>
      ))}

      <div className={styles.divider} />
      <div className={styles.totalRow}>
        <span>Total</span>
        <div className={styles.priceValue}>
          ₹<span>4,999</span>
        </div>
      </div>
      <div className={styles.divider} />

      <div className={styles.paymentOptions}>
        <div>
          {paymentOptions.map((option, index) => (
            <div className={styles.totalPayRow} key={`payment-${index}`}>
              <span>{option.label}</span>
              <div className={styles.priceValue}>
                ₹ <span>{option.value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
