import React from "react";
import styles from "../styles/RoomService.module.css";

export const RoomService = ({ service, isChargeable }) => {
  return (
    <div className={styles.service}>
      {service} {isChargeable && "- Additional charge"}
    </div>
  );
};
