import React from "react";
import styles from "./RoomCard.module.css";
import { Link } from "react-router-dom";

export const RoomCard = () => {
  return (
    <div className={styles.roomCard}>
      <div className={styles.cardContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.roomDetails}>
            <div className={styles.roomTitle}>
              Super Deluxe Room (with Balcony)
            </div>
            <div>Free Breakfast</div>
            <div className={styles.refundStatus}>Non - Refundable</div>
          </div>
          <div className={styles.priceInfo}>
            <span className={styles.taxText}>+ Rs.700</span>{" "}
            <span className={styles.taxNote}>Taxes & Fees</span>
          </div>
        </div>
        <div className={styles.actionContainer}>
          <button
            className={styles.detailsButton}
            tabIndex="0"
            aria-label="View more details"
          >
            More Details
          </button>
          <Link
            to={"/hotel-listing/rooms/review"}
            className={styles.priceButton}
            tabIndex="0"
            aria-label="Select room for Rs.2,355"
          >
            Rs.2,355
          </Link>
        </div>
      </div>
    </div>
  );
};
