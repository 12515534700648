import React from "react";
import orangeFlight from'../../../../../Assets/flightspage/orangeflightmobile.svg'
const FlightRoute = ({ departure, arrival, duration }) => {
  return (
    <div className="route-info">
      <div className="location-info">
        <div className="airport-code">{departure?.AirportCode}</div>
        <div className="city-name">{departure?.City}</div>
      </div>
      <div className="flight-duration">
        <div className="trip-icon">
          <div className="dash-line" />
          <img
            src={orangeFlight}
            alt="Airplane icon"
            className="plane-icon"
          />
          <div className="dash-line" />
        </div>
        <div className="duration-text">{duration}</div>
      </div>
      <div className="location-info">
        <div className="airport-code">{arrival?.AirportCode}</div>
        <div className="city-name">{arrival?.City}</div>
      </div>
    </div>
  );
}
export default FlightRoute