import React, { useEffect } from "react";
import NavbarNew from "../Navbar/NavbarNew";
import styles from "./CheckoutJourney.module.css";
import ProgressBar from "./CheckOutJourney/PassengerDetails/components/ProgressBar";
import PassengerDetails from "./CheckOutJourney/PassengerDetails/PassengerDetails";
import FlightItineary from "./CheckOutJourney/FlightItineary/FlightItineary";
import FlightBookingReview from "./CheckOutJourney/FinalReview/FlightBookingReview";
import { useSelector } from "react-redux";
import useWindowSize from "../../Hooks/useWindowSize";
import MobileCheckoutJourney from "./[mobileRes]/CheckoutJourney/MobileCheckoutJourney";
import { useNavigate } from "react-router-dom";
import PaymentOptions from "./CheckOutJourney/Payment/PaymentOptions";

const CheckoutJourney = () => {
  const size = useWindowSize();
  const showProgress = useSelector(
    (state) => state.checkoutJourney.journeyProgress
  );
  const navigate = useNavigate();
  const flight = useSelector(
    (state) => state.checkoutJourney.bookingData
  ).FlightDetails;
  useEffect(() => {
    if (!flight) navigate(-1);
  }, []);
  return (
    <div>
      {size.width > 767 ? (
        <>
          <NavbarNew />
          <div className={styles.checkoutProgressContainer}>
            <ProgressBar />
          </div>
          {showProgress === 0 && <FlightItineary />}
          {showProgress === 1 && <PassengerDetails />}
          {showProgress === 2 && <FlightBookingReview />}
          {showProgress === 3 && <PaymentOptions />}
        </>
      ) : (
        <MobileCheckoutJourney />
      )}
    </div>
  );
};

export default CheckoutJourney;
