import React from "react";
import styles from "./HotelFacility.module.css";

export const HotelFacility = ({ iconSrc, name, alt }) => {
  return (
    <div className={styles.facilityContainer}>
      {iconSrc}
      <div className={styles.facilityName}>{name}</div>
    </div>
  );
};
