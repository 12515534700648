import React from "react";
import styles from "../styles/HotelRoom.module.css";

export const AmenityList = ({ items, type }) => {
  const className =
    type === "included" ? styles.includedAmenities : styles.excludedAmenities;

  return (
    <div className={className}>
      {items.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};
