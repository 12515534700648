import React from "react";
import styles from "./Meal.module.css";
import RouteDisplay from "./Components/RouteDisplay";
import FoodCard from "./Components/FoodCard";

const menuItems = [
  {
    id: 1,
    title: "chicken Sandwich + beverage",
    price: "300",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4225a4ad41c02f1520db8b9a8b28e51f8659cb154b9d6330e0319708b14ad57?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 2,
    title: "Paneer roll + beverage",
    price: "350",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/3db205a6c8206943eca708c301415f2fb3e30ec96598428cf50352430f47fc74?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 3,
    title: "chicken burger+ beverage",
    price: "200",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b194e545e02ff260d3fbcffcd246de6046c4ce65bcf8162e0e3bf1b15046267?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 4,
    title: "chicken Sandwich + beverage",
    price: "150",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4225a4ad41c02f1520db8b9a8b28e51f8659cb154b9d6330e0319708b14ad57?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
];

const Meal = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.banner}>
        "Don't Let Your Stomach Grumble at 30,000 Feet – Pre-Book a Feast!"
      </h1>
      <div className={styles.content}>
        <RouteDisplay />
        <div className={styles.menuSection}>
          <div className={styles.menuColumn}>
            {menuItems.map((item, index) => (
              <FoodCard
                key={index}
                title={item.title}
                price={item.price}
                imageSrc={item.img}
                meal={item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Meal;
