import React, { Fragment, useEffect, useState } from "react";
import { PriceOption } from "./PriceOption";
import styles from "./FlightCard.module.css";
import FlightTabs from "../FlightDetails/FlightTabs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import planIcon from "../../../Assets/flightspage/greyFlight.svg";
import {
  setBookingData,
  setJourneyProgress,
} from "../../../features/CheckoutJourney/checkoutJourneySlice";

export const FlightCard = ({ flight, flightIndex }) => {
  const flightDatails = flight.FlightDetails;
  const [flightData, setFlightData] = useState(flight);
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const navigate = useNavigate();
  const [openFlightDetails, setOpenFlightDetails] = useState(false);
  const dispatch = useDispatch();
  const flightDetailopenCard = () => setOpenFlightDetails(!openFlightDetails);
  const priceOptionSelection = (e) => {
    console.log(e.target.value, "value");
    setFlightData((prev) => ({
      ...prev,
      FlightDetails: {
        ...prev.FlightDetails,
        selectedPrice: e.target.value,
      },
    }));
  };
  const redirectionWithFlightDetails = () => {
    if (!flightData.FlightDetails.selectedPrice) alert("please select a price");
    else {
      dispatch(
        setBookingData({
          ...bookingData,
          ...flightData,
        })
      );
      dispatch(setJourneyProgress(0));
      navigate("/flights/flightcheckout");
    }
  };
  useEffect(() => {
    if (flightDatails?.PriceOptions?.length > 0) {
      setFlightData((prev) => ({
        ...prev,
        FlightDetails: {
          ...prev.FlightDetails,
          selectedPrice: flightDatails.PriceOptions[0].price,
        },
      }));
    }
  }, [flightDatails]);
  console.log(flightData);
  return (
    <>
      <div className={styles.flightCard}>
        <div className={styles.cardContent}>
          <div className={styles.airlineInfo}>
            <div className={styles.airlineLogo}>
              <div className={styles.logoWrapper}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/227318a0a5b4920c2c1c2dd48527b163ef0b0b13c7448023e0205a95089301b4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                  className={styles.logo}
                  alt="Air India logo"
                />
                <div>{flightDatails?.Airline?.Name}</div>
              </div>
            </div>
          </div>

          <div className={styles.flightTiming}>
            <div className={styles.timeInfo}>
              <div className={styles.locationTime}>
                <div>{flightDatails?.Route?.Departure?.AirportCode}</div>
                <div className={styles.time}>
                  {flightDatails?.Route?.Departure?.Time}
                </div>
                <div className={styles.date}>
                  {flightDatails?.Route?.Departure?.Date}
                </div>
              </div>
              <div className={styles.stopInfo}>
                <div>{flightDatails.TotalStops}</div>
                <div className={styles.line} />
                <div className={styles.durationIndicator}>
                  <div className={styles.duration}>
                    {flightDatails?.Duration}
                  </div>
                  <img
                    loading="lazy"
                    src={planIcon}
                    className={styles.planeIcon}
                    alt="Plane icon"
                  />
                </div>
              </div>
              <div className={styles.locationTimeArriVal}>
                <div>{flightDatails?.Route?.Arrival?.AirportCode}</div>
                <div className={styles.time}>
                  {flightDatails?.Route?.Arrival?.Time}
                </div>
                <div className={styles.date}>
                  {flightDatails?.Route?.Arrival?.Date}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.priceSection}>
            <div className={styles.priceOptions}>
              {flightDatails?.PriceOptions?.map((option, index) => (
                <Fragment key={index}>
                  <PriceOption
                    priceIndex={index}
                    flightIndex={flightIndex}
                    price={option.price}
                    fareType={option.fareType}
                    onSelectPriceOption={priceOptionSelection}
                    selectedPrice={flightData?.FlightDetails?.selectedPrice}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.detailsButton}
            onClick={flightDetailopenCard}
          >
            View Details
          </button>
          <button
            className={styles.buyNowButton}
            onClick={redirectionWithFlightDetails}
          >
            Buy Now
          </button>
        </div>
        {openFlightDetails && <FlightTabs flight={flight} />}
      </div>
    </>
  );
};
