import * as React from "react";
const FlightLocation = ({ date, city }) => {
  return (
    <div className="location-info">
      <div className="date">{date}</div>
      <div className="city">{city}</div>
    </div>
  );
};
export default FlightLocation;
