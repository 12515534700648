import baggageIcon from '../../../../../Assets/flightspage/baggageIcon.svg'
import baggageIcon2 from '../../../../../Assets/flightspage/baggageIcon2.svg'
const BaggageInfo = ({ cabinWeight, checkinWeight }) => {
  return (
    <div className="baggage-info">
      <div className="cabin-baggage">
        <img
          src={baggageIcon}
          alt="Cabin baggage icon"
          className="baggage-icon"
        />
        <div className="baggage-details">
          <div className="weight-info">{cabinWeight} (1 passenger)</div>
          <div className="baggage-type">cabin baggage</div>
        </div>
      </div>
      <div className="checkin-baggage">
        <img
          src={baggageIcon2}
          alt="Check-in baggage icon"
          className="checkin-icon"
        />
        <div className="checkin-details">
          <div className="weight-info">{checkinWeight} (1 Passenger)</div>
          <div className="baggage-type">check-in baggage</div>
        </div>
      </div>
    </div>
  );
}
export default BaggageInfo;