import React, { Fragment, useEffect, useState } from "react";
import "./FlightListings.css";
import FlightCard from "./FlightCards/FlightCard";
import flights from "../../DummyFLights";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import FlightCheapestnBestFilter from "./FlightCards/FlightsCheapestnBestFilter";

const FlightListingMobile = () => {
  const navigate = useNavigate();
  const [flightDataToshow, setFlightDataToshow] = useState();
  useEffect(() => {
    const storedData = localStorage.getItem("flightData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData.flightDepartDate) {
        parsedData.flightDepartDate = new Date(parsedData.flightDepartDate);
      }
      if (parsedData.flightReturnDate) {
        parsedData.flightReturnDate = new Date(parsedData.flightReturnDate);
      }
      setFlightDataToshow(parsedData);
    }
  }, []);
  //   debugger
  return (
    <div className="flight-listing-wraper">
      <div className="location-card" onClick={() => navigate(-1)}>
        <div
          className="location-wrapper"
          role="region"
          aria-label="Location Information"
          tabIndex="0"
        >
          <ChevronLeft />
          <div className="location-name">
            {flightDataToshow?.flightFrom?.airportCode} -{" "}
            {flightDataToshow?.flightTo?.airportCode}
          </div>
        </div>
      </div>
      <FlightCheapestnBestFilter />

      {flights.map((flight, index) => (
        <Fragment key={index}>
          <FlightCard flightData={flight} />
        </Fragment>
      ))}
    </div>
  );
};

export default FlightListingMobile;
