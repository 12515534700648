import React from "react";
import styles from "./FlightBookingReview.module.css";
import PassengerCard from "./PassengerCard";
import FlightDetailsCard from "./FlightDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import { setJourneyProgress } from "../../../../features/CheckoutJourney/checkoutJourneySlice";

const FlightReviewCompo = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const dispatch = useDispatch();
  const FlightDetails = bookingData.FlightDetails;
  const gotoPayment = () => {
    dispatch(setJourneyProgress(3));
    console.log(bookingData);
  };
  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formGrid}>
          <div className={styles.journeyDetails}>
            <div className={styles.routeInfo}>
              <h3>
                {FlightDetails.Route?.Departure?.City} →{" "}
                {FlightDetails.Route?.Arrival?.City}{" "}
                <span>{FlightDetails.Route?.Departure?.Date}</span>
              </h3>
            </div>
            <FlightDetailsCard />
            <div className={styles.baggageInfo}>
              <p>
                (Adult) Check-in: {FlightDetails?.Baggage?.CheckIn}, Cabin:{" "}
                {FlightDetails?.Baggage?.Cabin}
              </p>
            </div>
          </div>
          <PassengerCard />
          <div className={styles.actions}>
            <button
              className={styles.backButton}
              onClick={() => dispatch(setJourneyProgress(1))}
            >
              Back
            </button>
            <button className={styles.payButton} onClick={gotoPayment}>
              Proceed To Pay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightReviewCompo;
