import React from "react";
import styles from "./HotelCard.module.css";

export function Rating({ score, likes }) {
  return (
    <div className={styles.ratingContainer}>
      <div className={styles.ratingScore}>
        ⭐️
        <div>{score}</div>
      </div>
      <div className={styles.ratingText}>{likes} people liked this</div>
    </div>
  );
}
