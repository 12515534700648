import React from "react";
import styles from "./FlightFilter.module.css";

export const FilterOptions = ({ label, onChange }) => {
  const checkboxId = label.split(" ").join("_");
  return (
    <div className={styles.optionContainer}>
      <input
        type="checkbox"
        name={checkboxId}
        id={checkboxId}
        className={styles.checkbox}
      />
      <span className={styles.custom_checkbox}></span>
      <label htmlFor={checkboxId} className={styles.checkbox_text}>
        {label}
      </label>
    </div>
  );
};
