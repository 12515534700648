import React from "react";
import "./MobileFareBreakUpPopup.css";
import FareItem from "./FareItem";
import { useDispatch, useSelector } from "react-redux";
import { setFareBreakUpsPopUp } from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const MobileFareBreakUpPopup = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const dispatch = useDispatch();
  const getFareDetails = bookingData.FareDetails;
  const transformFareDetails = () => {
    const fareDetails = [];
    fareDetails.push({
      label: "Base Fare",
      amount: formatAmount(
        bookingData.passengerDetails.length > 0
          ? getFareDetails.BaseFare * bookingData.passengerDetails.length
          : getFareDetails.BaseFare
      ),
      type: "header",
      subItems: [
        {
          label: `Adult(s) (1)`,
          amount: formatAmount(
            bookingData.passengerDetails.length > 0
              ? getFareDetails.BaseFare * bookingData.passengerDetails.length
              : getFareDetails.BaseFare
          ),
          type: "subitem",
        },
      ],
    });
    fareDetails.push({
      label: "Taxes & Fees",
      amount: formatAmount(
        bookingData.passengerDetails.length > 0
          ? getFareDetails.TaxesAndFees * bookingData.passengerDetails.length
          : getFareDetails.TaxesAndFees
      ),
      type: "header",
      subItems: [
        {
          label: "Airline Taxes & Fees",
          amount: formatAmount(
            bookingData.passengerDetails.length > 0
              ? getFareDetails.AirlineSurcharges *
                  bookingData.passengerDetails.length
              : getFareDetails.AirlineSurcharges
          ),
          type: "subitem",
        },
      ],
    });
    fareDetails.push({
      label: "Other Services",
      type: "header",
      amount: formatAmount(
        Object.values(getFareDetails.OptionalAddOns).reduce(
          (total, value) => total + value,
          0
        )
      ),
      subItems: [
        ...Object.entries(getFareDetails.OptionalAddOns).map(
          ([key, value]) => ({
            label: key,
            amount: formatAmount(value),
            type: "subitem",
          })
        ),
      ],
    });

    const discount = 900;
    fareDetails.push({
      label: "Instant Discount",
      amount: `-${formatAmount(discount)}`,
      type: "discount",
    });

    return fareDetails;
  };

  function formatAmount(amount) {
    if (typeof amount === "string") {
      return amount;
    }
    return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const calculateTotalPrice = (fareDetails = transformFareDetails()) => {
    let total = 0;

    fareDetails?.forEach((detail) => {
      if (detail?.type === "header" && detail.subItems) {
        total += parseAmount(detail?.amount);
      } else if (detail?.type === "discount") {
        total -= parseAmount(detail?.amount);
      }
    });

    return formatAmount(total);
  };
  function parseAmount(amount) {
    return parseInt(amount?.replace(/,/g, "")?.replace("-", ""), 10);
  }

  return (
    <>
      <div className="fare-breakup">
        <div className="breakup-container">
          <div className="fare-details">
            <div className="labels-column">
              <div className="title">Fare Breakup</div>
              {transformFareDetails().map((item, index) => (
                <>
                  {parseAmount(item.amount) > 0 ? (
                    <>
                      <FareItem key={index} {...item} />
                      {item.subItems && item.subItems.length > 0 && (
                        <>
                          {item.subItems.map((subItem, subIndex) => (
                            <>
                              {parseAmount(subItem.amount) > 0 ? (
                                <FareItem
                                  key={subIndex}
                                  label={subItem.label}
                                  amount={subItem.amount}
                                  type={subItem.type}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="divider" />
          <div className="total-row">
            <div>Grand Total</div>
            <div>{calculateTotalPrice()}</div>
          </div>
          <button
            className="continue-button"
            tabIndex="0"
            onClick={() => dispatch(setFareBreakUpsPopUp(false))}
          >
            Continue
          </button>
        </div>
      </div>
      <div
        className="fare-breakup-overlay"
        onClick={() => dispatch(setFareBreakUpsPopUp(false))}
      ></div>
    </>
  );
};
export default MobileFareBreakUpPopup;
