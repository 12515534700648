import React, { useEffect, useState } from "react";
import TestimonialCard from "./TestimonialCard";
import styles from "./TestimonialCard.module.css";
import axios from "axios";
import { BASE_URL } from "../../../../../utils";

const RoomTestimonials = () => {
  const [data, setData] = useState(null);
  const InflunecerApi = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}DataTransaction/Reviews?UserId`
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };
  useEffect(() => {
    InflunecerApi();
  }, []);

  return (
    <div className={styles.mainwrappertestimonail}>
      {data?.map((ele, id) => (
        <React.Fragment key={id}>
          <TestimonialCard data={ele} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default RoomTestimonials;
