import * as React from "react";
import orangeFlight from '../../../../../Assets/flightspage/orangeflightmobile.svg'

export default function FlightInfo({ origin, destination, duration }) {
  return (
    <>
      <div className="flight-info-container">
        <div className="location-info">
          <span className="location-code">{origin.AirportCode}</span>
          <span className="location-city">{origin.City}</span>
        </div>
        <div className="flight-duration">
          <div className="flight-path">
            <div className="path-line" />
            <img
              loading="lazy"
              src={orangeFlight}
              alt="Flight path indicator"
              className="flight-icon"
            />
            <div className="path-line" />
          </div>
          <span className="duration-text">{duration}</span>
        </div>
        <div className="location-info destination">
          <span className="location-code">{destination.AirportCode}</span>
          <span className="location-city">{destination.City}</span>
        </div>
      </div>
    </>
  );
}