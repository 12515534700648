import React from "react";
import "./DrawerContent.css";
import { Slider } from "@mui/material";
import { Search } from "lucide-react";
const DrawerContent = ({ filterType }) => {
  const renderFilterDrawer = () => {
    switch (filterType) {
      case "pricerange":
        return <PriceRange />;
      case "starrating":
        return <StarRatingsDisplay />;
      case "locationsearch":
        return <LocationSearch />;
      default:
        return null;
    }
  };

  return <>{renderFilterDrawer()}</>;
};

export default DrawerContent;

// Drawer Components Starts from Below______________________________________________________

const StarRatingsDisplay = () => {
  const ratings = [5, 4, 3, 2, 1];
  const StarRating = ({ rating }) => {
    const maxStars = 5;

    const stars = Array.from({ length: maxStars }, (_, index) => {
      const fillColor = index < rating ? "#FFD233" : "#AAA9A4";
      return (
        <svg
          key={index}
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M14.0489 2.92705C14.3483 2.00574 15.6517 2.00574 15.9511 2.92705L18.1432 9.67376C18.2771 10.0858 18.661 10.3647 19.0943 10.3647H26.1882C27.1569 10.3647 27.5597 11.6044 26.7759 12.1738L21.0369 16.3435C20.6864 16.5981 20.5397 17.0495 20.6736 17.4615L22.8657 24.2082C23.1651 25.1295 22.1106 25.8956 21.3269 25.3262L15.5878 21.1565C15.2373 20.9019 14.7627 20.9019 14.4122 21.1565L8.67312 25.3262C7.88941 25.8956 6.83493 25.1295 7.13428 24.2082L9.32642 17.4615C9.46029 17.0495 9.31363 16.5981 8.96315 16.3435L3.22405 12.1738C2.44034 11.6044 2.84311 10.3647 3.81184 10.3647H10.9057C11.339 10.3647 11.7229 10.0858 11.8568 9.67376L14.0489 2.92705Z"
            fill={fillColor}
          />
        </svg>
      );
    });

    return (
      <>
        <div style={{ display: "flex", gap: "5px" }}>{stars}</div>
      </>
    );
  };

  return (
    <div>
      <p style={{ lineHeight: "5rem" }} className="text-5xl text-gray-600 m-6 ">
        Hotel star ratings reflect the level of comfort and service, helping
        guests trust the quality and reliability of their stay.
      </p>
      <div className="flex items-center m-[4rem] flex-col">
        {ratings.map((rating) => (
          <div
            key={rating}
            className="flex w-full justify-between my-9 items-center"
          >
            <StarRating rating={rating} />
            <input className="h-[6rem] w-[6rem]" type="checkbox" />
          </div>
        ))}
      </div>
    </div>
  );
};

const PriceRange = () => {
  const [value, setValue] = React.useState([2000, 3007]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <p className="text-left">Price Ranges</p>
      <div className="flex m-9 text-gray-700 justify-between">
        <p>{value[0]}</p>
        <p>{value[1]}</p>
      </div>
      <Slider
        min={2000}
        max={10000}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        sx={{ color: "#FE6F61" }}
      />
    </>
  );
};

const LocationSearch = () => {
  return (
    <>
      <p className="text-[3rem] my-9">Select your preferred stay location</p>

      <div>
        <div className="search border-2 p-9 rounded-[3rem] flex items-center ">
          <Search />
          <input
            type="text"
            className="text-[4rem] px-4"
            placeholder="search Locations"
          />
        </div>
        <div className="bg-gray-100 p-12 my-12">No suggestion Found!</div>
      </div>
    </>
  );
};
