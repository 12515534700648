import React from "react";
import styles from "./styles/HotelRoom.module.css";
import { AmenityList } from "./components/AmenityList";
import { Link } from "react-router-dom";
import { Star } from "lucide-react";

export const HotelCard = () => {
  const includedAmenities = [
    "Free breakfast",
    "Free self parking",
    "Free Wi-Fi",
  ];
  const excludedAmenities = [
    "Non refundable",
    "Mineral water - Additional charge",
    "Heater - Additional charges",
  ];

  return (
    <div className={styles.container}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c10f5596b9ae664764102fb2b552a3f23cb2820e76338b97ad83d86042cd652?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
        className={styles.roomImage}
        alt="Super Deluxe Room with Balcony"
      />
      <div className={styles.contentWrapper}>
        <div className={styles.headerSection}>
          <div className={styles.mainInfo}>
            <div className={styles.ratingContainer}>
              <div className={styles.ratingScore}>
                <Star color="yellow" />
                <div>3.9</div>
              </div>
              <div className={styles.ratingText}>85/100 people liked this</div>
            </div>
            <div className={styles.roomTitle}>
              Super Deluxe Room (with Balcony)
            </div>
            <div className={styles.amenitiesContainer}>
              <AmenityList items={includedAmenities} type="included" />
              <AmenityList items={excludedAmenities} type="excluded" />
            </div>
          </div>
          <div className={styles.discountBadge}>20% off</div>
        </div>
        <div className={styles.footerSection}>
          <div className={styles.amenityText}>Free breakfast</div>
          <div className={styles.taxInfo}>
            <div>+ 280 taxes &fees/Night</div>
          </div>
        </div>
        <div className={styles.footerSection}>
          <div className={styles.amenityText}>Free breakfast</div>
          <div className={styles.actionSection}>
            <button className={styles.moreServicesButton} tabIndex="0">
              + More Services
            </button>
            <Link to={"rooms/asd"} className={styles.priceTag}>
              ₹1,598
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
