import { ChevronLeft } from "lucide-react";
import React from "react";
import "./meals.css";
import { setFareBreakUpsPopUp } from "../../../../../../../features/CheckoutJourney/checkoutJourneySlice";
import { useDispatch } from "react-redux";

const menuItems = [
  {
    id: 1,
    title: "chicken Sandwich + beverage",
    price: "300",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4225a4ad41c02f1520db8b9a8b28e51f8659cb154b9d6330e0319708b14ad57?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 2,
    title: "Paneer roll + beverage",
    price: "350",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/3db205a6c8206943eca708c301415f2fb3e30ec96598428cf50352430f47fc74?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 3,
    title: "chicken burger+ beverage",
    price: "200",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b194e545e02ff260d3fbcffcd246de6046c4ce65bcf8162e0e3bf1b15046267?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 4,
    title: "chicken Sandwich + beverage",
    price: "150",
    img: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4225a4ad41c02f1520db8b9a8b28e51f8659cb154b9d6330e0319708b14ad57?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
];

const Meals = ({
  allMeals,
  onAddingMeals,
  onDecreaseMeals,
  onIncreaseMeals,
  backButtonActionToPrev,
}) => {
  const dispatch = useDispatch();
  const existingMeals = (meal) => {
    return allMeals?.find((m) => m.id === meal.id);
  };
  return (
    <div className="meals-container">
      <div className="backBtn-wraper">
        <ChevronLeft onClick={() => backButtonActionToPrev()} />
        <h2 className="selector-title">Add Meal</h2>
      </div>
      <div className="meals-content">
        <div className="meals-mealTypes">
          <div className="meals-mealType">
            <input type="checkbox" id="veg" className="meals-indicator" />
            <label htmlFor="veg">Veg</label>
          </div>
          <div className="meals-mealType">
            <input type="checkbox" id="non-veg" className="meals-indicator" />
            <label htmlFor="non-veg">Non-Veg</label>
          </div>
          <div className="meals-mealType">
            <input type="checkbox" id="vegan" className="meals-indicator" />
            <label htmlFor="vegan">Vegan</label>
          </div>
        </div>

        <div className="meals-menuSection">
          <div className="meals-menuColumn">
            {menuItems.map((item, index) => (
              <div className="meals-foodCardContainer" key={index}>
                <img src={item?.img} alt="" className="meals-foodCardMainImg" />
                <div className="meals-foodCard">
                  <div className="meals-cardContent">
                    <div className="meals-titlePrice">
                      <h3 className="meals-menuItemTitle">{item.title}</h3>
                      {existingMeals(item) ? (
                        <div className="meals-inc-dnc-wrapper">
                          <button
                            className="meals-decbtn"
                            onClick={() => onDecreaseMeals(item)}
                          >
                            -
                          </button>
                          <span className="meals-quantity">
                            {existingMeals(item).quantity}
                          </span>
                          <button
                            className="meals-incbtn"
                            onClick={() => onIncreaseMeals(item)}
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <button
                          className="meals-addButton"
                          onClick={() => onAddingMeals(item)}
                        >
                          Add
                        </button>
                      )}
                    </div>
                    <div className="meals-priceWrapper">
                      &#8377;
                      <span className="meals-price">{item?.price}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <button
          className="fare-button"
          onClick={() => dispatch(setFareBreakUpsPopUp(true))}
        >
          Fare Breakup
        </button>
        <button
          className="review-button"
          onClick={() => backButtonActionToPrev()}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Meals;
