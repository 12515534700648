import React from "react";

const FacilityItemPrint = ({ item, passenger }) => {
  return (
    <>
      {item.name === "Baggage" &&
        (passenger?.baggage.length > 0 ? (
          <div>
            {passenger.baggage.reduce(
              (total, baggage) => total + baggage.weight,
              0
            )}{" "}
            KG
          </div>
        ) : (
          "No baggage selected"
        ))}
      {item.name === "Seat Selection" &&
        (!passenger?.seat.seatNumber
          ? "No seat selected"
          : passenger?.seat.seatNumber)}
      {item.name === "Meal" &&
        (passenger?.meals.length > 0
          ? passenger?.meals.map((meal, mealIndex) => (
              <div key={mealIndex}>{meal.title}</div>
            ))
          : "No meals selected")}
    </>
  );
};

export default FacilityItemPrint;
