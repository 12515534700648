import React, { useState } from "react";
import { FacilitySection } from "./FacilitySection";
import styles from "./RoomFacilities.module.css";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../../../../Hooks/useWindowSize";

// Mock items for facility sections
const layoutItems = [
  { text: "Bed" },
  { text: "Window" },
  { text: "Desk" },
  { text: "Trash cans" },
];

const bathroomItems = [
  { text: "Bathrobes" },
  { text: "Towels" },
  { text: "Mirror" },
];

const comfortItems = [
  { text: "Air conditioning" },
  { text: "Fan" },
  { text: "Daily newspaper" },
];

const entertainmentItems = [
  { text: "Free Wi-Fi" },
  { text: "Telephone" },
  { text: "Satellite/Cable channels" },
];

// Modal Component
const Modal = ({ onClose, children }) => (
  <div className={styles.modalOverlay} onClick={onClose}>
    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
      <button className={styles.closeButton} onClick={onClose}>
        ×
      </button>
      {children}
    </div>
  </div>
);

export const RoomFacilities = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleMoreServicesClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Room Facilities</h1>
        <div className={styles.divider} />

        <div className={styles.facilitiesGrid}>
          <div className={styles.facilitiesRow}>
            <FacilitySection title="Room Features" items={layoutItems} />
            {size.width > 469 && (
              <FacilitySection
                title="Bathroom and toiletries"
                items={bathroomItems}
              />
            )}
            <FacilitySection title="Comforts" items={comfortItems} />
            <FacilitySection title="Entertainment" items={entertainmentItems} />
          </div>
        </div>

        <div className={styles.actionButtons}>
          <button
            className={styles.moreButton}
            onClick={handleMoreServicesClick}
          >
            + More Services
          </button>
          <button
            onClick={() => navigate(`/hotel-listing/rooms/payment`)}
            className={styles.paymentButton}
          >
            Proceed Payment
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <ul>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Room Service
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Laundry Service
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Free Parking
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Mini Bar
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Bar
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Clothing Iron
            </li>
            <li className="flex gap-2">
              {" "}
              <input type="checkbox" />
              Computer Facility
            </li>
          </ul>
          <div
            onClick={handleCloseModal}
            className="flex cursor-pointer justify-end"
          >
            <div className="bg-[red] text-white py-1 px-4 rounded-lg w-[5rem]">
              Done
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
