import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setBookingData,
  setJourneyProgress,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

export default function AirlineInfo({ airline, price, flightData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);

  const redirectionWithFlightDetails = () => {
    // if (!flightData?.FlightDetails.selectedPrice)
    //   alert("please select a price");
    // else {
    dispatch(setJourneyProgress(0));
    dispatch(
      setBookingData({
        ...bookingData,
        ...flightData,
      })
    );
    navigate("/flights/flightcheckout");
    // }
  };
  return (
    <>
      <div className="airline-container">
        <div className="airline-details">
          <img
            loading="lazy"
            src={airline.Logo}
            alt={`${airline.Name} logo`}
            className="airline-logo"
          />
          <span className="airline-name">{airline.Name}</span>
        </div>
        <span
          className="aeroplane-price"
          onClick={redirectionWithFlightDetails}
        >
          {price}
        </span>
      </div>
    </>
  );
}
