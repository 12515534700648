import React from "react";
import { HotelImageGallery } from "../../hotel_components/Banner/HotelDetailBanner/Hotelgallery/HotelImageGallery";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import HotelDetailSections from "./HotelDetailSections";
import RoomSelector from "./components/roomTypes/RoomSelector";
import useWindowSize from "../../../Hooks/useWindowSize";
import HotelDetailMobile from "../../[Mobile]/hotel-detail/HotelDetailMobile";

const HotelDetailPage = () => {
  const size = useWindowSize();
  return (
    <>
      <NavbarNew />
      {size.width > 489 ? (
        <>
          <HotelImageGallery />
          <HotelDetailSections />
          <RoomSelector />
        </>
      ) : (
        <HotelDetailMobile />
      )}
    </>
  );
};

export default HotelDetailPage;
