import * as React from "react";
import PaymentOptions from "./PaymentOptions";
import { paymentMethodsData } from "./styles";
const PaymentMethods = () => {
  return (
    <div className="payment-container">
      <div className="payment-wrapper">
        {paymentMethodsData.map((payment, index) => (
          <React.Fragment key={payment.id}>
            <PaymentOptions
              icon={payment.icon}
              title={payment.title}
              description={payment.description}
            />
            {index < paymentMethodsData.length - 1 && (
              <div className="divider" />
            )}
          </React.Fragment>
        ))}
      </div>
      <style jsx>{`
        .payment-container {
          border-radius: 0px 0px 0px 0px;
          display: flex;
          max-width: 427px;
          flex-direction: column;
          font-family: Inter, sans-serif;
        }
        .payment-wrapper {
          border-radius: 10px;
          background-color: rgba(240, 240, 240, 1);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 57px 3px;
        }
        .divider {
          margin: 8px 0;
          height: 3px;
          border: 1px solid rgba(218, 218, 218, 1);
        }
      `}</style>
    </div>
  );
};
export default PaymentMethods;
