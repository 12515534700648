import React from "react";
import styles from "../styles/PriceRow.module.css";

export const PriceRow = ({ label, amount, currency = "₹" }) => {
  return (
    <div className={styles.priceRow}>
      <span className={styles.label}>{label}</span>
      <div className={styles.amount}>
        <span>₹{amount}</span>
      </div>
    </div>
  );
};
