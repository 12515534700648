import React from "react";
import styles from "./RoomFacilities.module.css";
import { Check } from "lucide-react";

export const FacilityItem = ({ icon, text }) => {
  return (
    <div className={styles.facilityItem}>
      <Check color="green" />
      <div>{text}</div>
    </div>
  );
};
