import React from "react";
import styles from "../PassengerDetails.module.css";
import SideflightDetails from "./SideflightDetails";
import { useSelector } from "react-redux";

export const FlightSummary = ({ flightDetails = false }) => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const getFareDetails = bookingData.FareDetails;
  const transformFareDetails = () => {
    const fareDetails = [];
    fareDetails.push({
      label: "Base Fare",
      amount: formatAmount(
        bookingData.passengerDetails.length > 0
          ? getFareDetails.BaseFare * bookingData.passengerDetails.length
          : getFareDetails.BaseFare
      ),
      type: "header",
      subItems: [
        {
          label: `Adult(s) (1)`,
          amount: formatAmount(
            bookingData.passengerDetails.length > 0
              ? getFareDetails.BaseFare * bookingData.passengerDetails.length
              : getFareDetails.BaseFare
          ),
          type: "subitem",
        },
      ],
    });
    fareDetails.push({
      label: "Taxes & Fees",
      amount: formatAmount(
        bookingData.passengerDetails.length > 0
          ? getFareDetails.TaxesAndFees * bookingData.passengerDetails.length
          : getFareDetails.TaxesAndFees
      ),
      type: "header",
      subItems: [
        {
          label: "Airline Taxes & Fees",
          amount: formatAmount(
            bookingData.passengerDetails.length > 0
              ? getFareDetails.AirlineSurcharges *
                  bookingData.passengerDetails.length
              : getFareDetails.AirlineSurcharges
          ),
          type: "subitem",
        },
      ],
    });
    fareDetails.push({
      label: "Other Services",
      type: "header",
      amount: formatAmount(
        Object.values(getFareDetails.OptionalAddOns).reduce(
          (total, value) => total + value,
          0
        )
      ),
      subItems: [
        ...Object.entries(getFareDetails.OptionalAddOns).map(
          ([key, value]) => ({
            label: key,
            amount: formatAmount(value),
            type: "subitem",
          })
        ),
      ],
    });

    const discount = 900;
    fareDetails.push({
      label: "Instant Discount",
      amount: `-${formatAmount(discount)}`,
      type: "discount",
    });

    return fareDetails;
  };

  function formatAmount(amount) {
    if (typeof amount === "string") {
      return amount;
    }
    return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const calculateTotalPrice = (fareDetails = transformFareDetails()) => {
    let total = 0;

    fareDetails?.forEach((detail) => {
      if (detail?.type === "header" && detail.subItems) {
        total += parseAmount(detail?.amount);
      } else if (detail?.type === "discount") {
        total -= parseAmount(detail?.amount);
      }
    });

    return formatAmount(total);
  };
  function parseAmount(amount) {
    return parseInt(amount?.replace(/,/g, "")?.replace("-", ""), 10);
  }
  return (
    <div className={styles.flightDetails}>
      {flightDetails && <SideflightDetails />}

      <div className={styles.fareBox}>
        <h3>FARE SUMMARY</h3>
        <div className={styles.fareDivider} />
        {transformFareDetails().map((item, index) => (
          <>
            <div className={styles.fareRow} key={index}>
              <span>{item?.label}</span>
              <span>{item?.amount}</span>
            </div>
            <div className={styles.fareDivider} />
          </>
        ))}
        <div className={styles.fareRow}>
          <span>SubTotal : </span>
          <span>{calculateTotalPrice()}</span>
        </div>
      </div>
    </div>
  );
};
