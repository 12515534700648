import * as React from "react";
import styles from "./TestimonialCard.module.css";

export default function TestimonialCard({ data }) {
  return (
    <div className={styles.testimonialCard}>
      <div className={styles.cardWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.testimonialInfo}>
            <div className={styles.testimonialContent}>
              <div className={styles.authorSection}>
                <div className={styles.authorName}>{data?.TravelerName}</div>
                <div className="flex">
                  {Array.from({ length: 5 }, (_, id) => (
                    <img
                      key={id}
                      className="w-[4rem] md:w-[1rem]"
                      src="	https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/rattingstar.png"
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div className={styles.testimonialText}>
                {data?.ReviewText.slice(0, 80) + ".."}
              </div>
              <div className={styles.location}>{data?.Destination}</div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              loading="lazy"
              src={data?.TravelerImage}
              className={styles.testimonialImage}
              alt={data?.TravelerName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
