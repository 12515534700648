export const paymentMethodsData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c33a0299bebb9cc022a9866eaf71d852ed8abb3f99b973e059b3238c51ac0089?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d",
    title: "G Pay",
    description: "Pay With Google Pay",
    id: "gpay",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/10bb8f197b381aa8b60fad9781876551ea2604375efa6c2ad9b5e936bcd433f7?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d",
    title: "UPI Options",
    description: "Pay Directly From Your Bank Account",
    id: "upi",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fbd7a8b1b52acfb0a1f259ae9339c706d5671ca389d0ffd2e9c9ab90b564a278?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d",
    title: "Phone Pay",
    description: "Pay With Phone Pay",
    id: "phonepay",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7a78d0576c390456a0c15dd426564ba23b84678fa0b735d95517dcfa45df0e6f?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d",
    title: "Credit/Debit card",
    description: "Pay Directly From Your Bank Account",
    id: "card",
  },
];
