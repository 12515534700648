import React from "react";
import "./MobileCheckoutJourney.css";
import Flightsdetails from "./Flightsreview/Flightsdetails";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  setAddPassanger,
  setJourneyProgress,
  setPassengerIndex,
} from "../../../../features/CheckoutJourney/checkoutJourneySlice";
import PassengerDetails from "./PassengerDetails/PassengerDetails";
import FinalReview from "./FinalReview/FinalReview";
import MobileFareBreakUpPopup from "./FareBreakUpPopup.jsx/MobileFareBreakUpPopup";
import MobilePayment from "./PaymentFlight/MobilePayment";

const MobileCheckoutJourney = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fareBreakups = useSelector(
    (state) => state.checkoutJourney.fareBreakups
  );
  const showProgress = useSelector(
    (state) => state.checkoutJourney.journeyProgress
  );
  const addPassenger = useSelector(
    (state) => state.checkoutJourney.addPassenger
  );
  const backButtonAction = () => {
    if (showProgress === 0) navigate(-1);
    else if (showProgress > 0) {
      if (showProgress === 1 && addPassenger) {
        dispatch(setAddPassanger(false));
        return;
      }
      dispatch(setPassengerIndex(null));
      dispatch(setJourneyProgress(showProgress - 1));
    } else console.error("Invalid journey progress value:", showProgress);
  };
  return (
    <>
      <div
        className={`backBtn-wraper ${showProgress === 3 ? "payment-bg" : ""}`}
      >
        <ChevronLeft onClick={() => backButtonAction()} />
        <div className="heading-wrapper">
          <h2 className="selector-title">
            {showProgress === 0 && "Flight Details"}
            {showProgress === 1 && "Passenger details"}
            {showProgress === 2 && "Review"}
            {showProgress === 3 && "Payment"}
          </h2>
          {showProgress === 3 && (
            <div className="paymentSubtotal">Total: &#8377; 39800</div>
          )}
        </div>
      </div>
      {showProgress === 0 && <Flightsdetails />}
      {showProgress === 1 && <PassengerDetails />}
      {showProgress === 2 && <FinalReview />}
      {showProgress === 3 && <MobilePayment />}
      {fareBreakups && <MobileFareBreakUpPopup />}
    </>
  );
};

export default MobileCheckoutJourney;
