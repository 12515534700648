import React, { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
// import Navbar from "../../../Navbar/navbar";
import Footer from "../../../Footer/Footer";
import useWindowSize from "../../../../Hooks/useWindowSize";
import { BASE_URL } from "../../../../utils";
import ThingsTodoSuggestions from "./ThingsTodoSuggestions";
import NavbarNew from "../../../Navbar/NavbarNew";

function ThingsTodoDetail() {
  const { id, Destination } = useParams();
  const size = useWindowSize();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await axios.get(
          `https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/activitysightseen?DestinationName=${Destination}&ActivityId=${id}`
        );
        const fetchedBlogs = response?.data?.Items[0];
        setData(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch Data", error);
      }
    };

    fetchDestinations();
  }, [id]);

  return (
    <>
      <NavbarNew />

      <BlogImage
        vi={size.width > 468 ? false : true}
        data={{ ...data, Destination }}
      />

      <div className="Content_Blogs">
        <div className="Blogs-Content">
          <div className="Blogs-Para">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {data?.DetailDescription}
            </ReactMarkdown>
          </div>
        </div>
      </div>

      <ThingsTodoSuggestions data={{ ...data }} />
      <Footer />
    </>
  );
}

export default ThingsTodoDetail;

export const BlogImage = ({ data, vi }) => {
  return (
    <Link className="Blogs_parent">
      <div className="BlogheroImg">
        <img className="HeroBannerImage" src={data?.Url} alt="" />
        {!vi && (
          <div className="darkContent">
            <p className="source_para">Source: Jour</p>
            <p className="head">{data?.Title}</p>

            <p className="minifooter">
              You may now need a visa if you’re travelling to{" "}
              {data?.Destination}.
            </p>
            <div className="btn-grpblog flex gap-4">
              <button>Travel</button>
              <button>Visa</button>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
