import React, { useState } from "react";
import "./PaymentOptions.css";
import CreditDebitOption from "./PaymentOptionsDesign/CreditDebitOption";
import UpiOptions from "./PaymentOptionsDesign/UpiOptions";
import { FlightSummary } from "../PassengerDetails/components/FlightSummary";
import PaymentFlightCard from "./PaymentFlightCard.jsx/PaymentFlightCard";

const PaymentOptions = () => {
  const [activeTab, setActiveTab] = useState("CreditDebit");

  const renderContent = () => {
    switch (activeTab) {
      case "CreditDebit":
        return <CreditDebitOption />;
      case "GPay":
        return <div>Google Pay Payment Coming Soon!</div>;
      case "UPIOptions":
        return <UpiOptions />;
      case "PhonePay":
        return <div>Phone Pay Payment Coming Soon!</div>;
      default:
        return null;
    }
  };

  return (
    <div className="payment-option-wrapper">
      <div className="payment-options">
        <h2>Payment Options</h2>
        <div className="tabs">
          <div
            className={`tab-payment ${
              activeTab === "CreditDebit" ? "active" : ""
            }`}
            onClick={() => setActiveTab("CreditDebit")}
          >
            Credit/Debit card
            <h6>Pay Directly From Your Bank Account</h6>
          </div>
          <div
            className={`tab-payment ${
              activeTab === "UPIOptions" ? "active" : ""
            }`}
            onClick={() => setActiveTab("UPIOptions")}
          >
            UPI Options
            <h6>Pay Directly From Your Bank Account</h6>
          </div>
          <div
            className={`tab-payment ${
              activeTab === "PhonePay" ? "active" : ""
            }`}
            onClick={() => setActiveTab("PhonePay")}
          >
            Phone Pay
            <h6>Pay With Phone Pay</h6>
          </div>
          <div
            className={`tab-payment ${activeTab === "GPay" ? "active" : ""}`}
            onClick={() => setActiveTab("GPay")}
          >
            G Pay
            <h6>Pay With Google Pay</h6>
          </div>
        </div>
        <div className="content">{renderContent()}</div>
      </div>
      <div className="rightColumn">
        <PaymentFlightCard />
        <FlightSummary />
      </div>
    </div>
  );
};

export default PaymentOptions;
