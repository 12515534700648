import React from "react";
import { DateInput } from "./DateInput";
import { GalleryImage } from "./GalleryImage";
import styles from "./HotelBooking.module.css";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import useHotelSearch from "../../../Hooks/useHotelSearch";
import { useNavigate } from "react-router-dom";
import { Locate } from "lucide-react";

export function HotelMobileSearch() {
  const navigate = useNavigate();
  const {
    openCalendar,
    searchQuery,
    counts,
    checkInDate,
    checkOutDate,
    handleCheckInDateChange,
    handleCheckOutDateChange,
    toggleDropdown,
    setSearchQuery,
    updateCount,
    handleCalendarToggle,
    setIsOpen,
  } = useHotelSearch();

  const searchHotelList = () => {
    const params = {
      searchtext: searchQuery.searchtext,
      checkin: checkInDate,
      checkout: checkOutDate,
      acr: `${counts.Adults}${counts.Children}${counts.Rooms}`,
    };
    setSearchQuery(params);
    const queryParams = new URLSearchParams(params).toString();
    navigate(`/hotel-listing?${queryParams}`);
  };
  const galleryImages = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/25065b4a-dafb-4cef-aa9f-aba142e1cdc5?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
      alt: "Hotel preview image 1",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/14674aa8-3016-4016-9280-2627029a26ac?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
      alt: "Hotel preview image 2",
    },
  ];

  return (
    <div className={styles.bookingPage}>
      <NavbarNew />
      <div className={styles.container}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0246033cb1e556085c5790d2339934e77256c5c3c1ccb9a321040d4803c31524?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
          className={styles.backgroundImage}
          alt="Hotel background"
        />

        <div className={styles.bookingCard}>
          <h1 className={styles.welcomeTitle}>
            Welcome to your next Adventure!
          </h1>
          <h2 className={styles.subtitle}>
            Discover the Perfect Stay with WanderStay
          </h2>
          <form>
            <label htmlFor="location" className={styles.locationLabel}>
              Where?
            </label>
            <div className={styles.locationInput}>
              <Locate />
              <input
                type="text"
                id="location"
                className={styles.locationText}
                placeholder="Ex: New York"
              />
            </div>
            <div className={styles.dateSection}>
              <DateInput
                translateX={"5%"}
                label={"Check In"}
                isOpen={openCalendar === "Check In"}
                onToggle={() => handleCalendarToggle("Check In")}
                onDateChange={handleCheckInDateChange}
              />
              <DateInput
                translateX={"-34%"}
                label={"Check Out"}
                isOpen={openCalendar === "Check Out"}
                onToggle={() => handleCalendarToggle("Check Out")}
                onDateChange={handleCheckInDateChange}
              />
            </div>
            <div className={styles.guestLabel}>Guests</div>
            <div className={styles.roomLabel}>Room</div>
            <div className={styles.imageGallery}>
              {galleryImages.map((image, index) => (
                <GalleryImage key={index} src={image.src} alt={image.alt} />
              ))}
            </div>
            <div
              onClick={() => searchHotelList()}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button className={styles.findButton}>FIND</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
