import React from "react";
import styles from "./FilterButton.module.css";
import { SlidersHorizontal } from "lucide-react";

export const FilterButton = ({ icon, label }) => {
  return (
    <button style={{ width: "100%" }} className={styles.filterButton}>
      <SlidersHorizontal className={styles.icon} />
      {label}
    </button>
  );
};
