import React from "react";
import { useSelector } from "react-redux";

const MobilePassengerCard = ({ passenger }) => {
  const passengerDetails = useSelector(
    (state) => state.checkoutJourney.bookingData
  ).passengerDetails;
  return (
    <div>
      <table>
        <thead>
          <tr className="passenger-card-header">
            <th>Sno.</th>
            <th>Passenger</th>
            <th>Seat</th>
            <th>Meal</th>
            <th>Add on baggage</th>
          </tr>
        </thead>
        <tbody>
          {passengerDetails.map((passenger, index) => (
            <tr key={index} className="passenger-card-body">
              <td>{index + 1}</td>
              <td>{`${passenger.firstName} ${passenger.middleName} ${passenger.lastName}`}</td>
              <td>{passenger?.seat?.seatNumber}</td>
              <td>
                {passenger?.meals.length > 0 ? (
                  <>
                    {passenger?.meals?.map((item, index) => (
                      <>
                        <span key={index}>{item.title}</span>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  "NA"
                )}
              </td>
              <td>
                {passenger?.baggage.length > 0 ? (
                  <>
                    {passenger.baggage.reduce(
                      (total, baggage) => total + baggage.weight,
                      0
                    )}{" "}
                    KG
                  </>
                ) : (
                  "NA"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MobilePassengerCard;
