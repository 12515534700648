import React from "react";
import HotelHeader from "./hotel-header/HotelHeader";
import "./hotelDetail.css";
import RoomTestimonials from "../../pages/RoomDetailPage/components/testimonials/RoomTestimonials";
import { HotelDescriptions } from "./hotel-descriptions/HotelDescriptions";
import RoomSelector from "../../pages/HotelDetailPage/components/roomTypes/RoomSelector";
import { ReviewGallery } from "../../pages/RoomDetailPage/components/ReviewGallery/ReviewGallery";
import { HotelCard } from "./hotelCard/HotelCard";
const HotelDetailMobile = () => {
  const filterOptions = ["OverView", "Photo", "Review", "Location"];
  return (
    <div>
      <HotelHeader />
      <div className="flex filterdivwrpper">
        {filterOptions.map((ele) => (
          <div className="filterdiv">{ele}</div>
        ))}
      </div>
      <HotelDescriptions />
      <HotelCard />
      <ReviewGallery />
      <RoomTestimonials />
    </div>
  );
};

export default HotelDetailMobile;
