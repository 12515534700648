import React, { Fragment, useEffect, useState } from "react";
import "./PassengerDetails.css";
import { PlusIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import PassengerCard from "./PassengerCard";
import PassengerForm from "./PassengerSelections/PassengerForm";
import {
  setAddPassanger,
  setFareBreakUpsPopUp,
  setJourneyProgress,
  setPassengerIndex,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const PassengerDetails = () => {
  const addPassenger = useSelector(
    (state) => state.checkoutJourney.addPassenger
  );
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const passengerData = bookingData?.passengerDetails;
  const [paxesNo, setPaxesNo] = useState();
  const settingStoreToaddnew = () => {
    if (passengerData.length < paxesNo) {
      dispatch(setAddPassanger(true));
      dispatch(setPassengerIndex(passengerData.length));
    }
  };
  useEffect(() => {
    const storedData = localStorage.getItem("flightData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData.flightPaxNChilds) {
        let paxNumber = Object.values(parsedData.flightPaxNChilds).reduce(
          (sum, value) => sum + value,
          0
        );
        setPaxesNo(paxNumber);
      }
    }
  }, []);
  const checkDataNRedirections = () => {
    if (passengerData.length > 0) dispatch(setJourneyProgress(2));
    else alert("please fill up passenger details");
  };

  return (
    <>
      {addPassenger ? (
        <PassengerForm />
      ) : (
        <div className="passenger-container">
          {passengerData.length > 0 && (
            <>
              <div className="passenger-heading">
                <h2>Passenger Info</h2>
                (For E-Ticket/Voucher)
              </div>
              {passengerData.map((passenger, index) => (
                <Fragment key={index}>
                  <PassengerCard passenger={passenger} index={index} />
                </Fragment>
              ))}
            </>
          )}
          <div
            className={`header-section ${
              passengerData.length < paxesNo ? "" : "disable-addpassenger"
            }`}
          >
            <div className="header-text">Add Passenger</div>
            <PlusIcon onClick={() => settingStoreToaddnew()} />
          </div>
          <div className="action-buttons">
            <button
              className="fare-button"
              onClick={() => dispatch(setFareBreakUpsPopUp(true))}
            >
              Fare Breakup
            </button>
            <button className="review-button" onClick={checkDataNRedirections}>
              Review
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default PassengerDetails;
