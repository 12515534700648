import React from "react";
import styles from "./SeatBooking.module.css";
import { LegendItem } from "./components/LegendItem";
import { PassengerCard } from "./components/PassengerCard";
import { useDispatch, useSelector } from "react-redux";
import { setBaggageSeat } from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const SeatBooking = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const dispatch = useDispatch();
  const passengerIndex = useSelector(
    (state) => state.checkoutJourney.baggageSeat
  ).passengerIndex;
  const legendItems = [
    { type: "selected", label: "Selected" },
    { type: "unavailable", label: "Unavailable" },
    { type: "available", label: "Available" },
  ];
  const passenger = bookingData.passengerDetails[passengerIndex];
  const flightData = bookingData.FlightDetails;
  const proceedToNext = () => {
    dispatch(setBaggageSeat({ seatPopUp: false, passengerIndex: null }));
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.flightInfo}>
          <div className={styles.airlineInfo}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e45d98dd0f84a876768a656d309dc94e490ae2b8f4006198774a4c841678ad4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
              className={styles.airlineLogo}
              alt="Air India logo"
            />
            <div className={styles.airlineName}>
              {flightData?.Airline?.Name}
            </div>
          </div>
          <div className={styles.locationName}>
            {flightData?.Route?.Departure?.City}
          </div>
        </div>
        <div className={styles.destinationInfo}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb70c7f544eb8edf62f8464331400d8a4afd72e6ef5a2b75665254760fba407d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
            className={styles.destinationIcon}
            alt="Destination icon"
          />
          <div className={styles.destinationName}>
            {flightData?.Route?.Arrival?.City}
          </div>
          <div className={styles.flightDate}>
            {flightData?.Route?.Departure?.Date}
          </div>
        </div>
      </div>

      <div className={styles.legendContainer}>
        {legendItems.map((item) => (
          <LegendItem key={item.type} type={item.type} label={item.label} />
        ))}
      </div>
      {passenger?.seat?.seatNumber && (
        <PassengerCard>
          <div className={styles.passengerDetails}>
            <div>{`${passenger?.firstName} ${passenger?.middleName} ${passenger?.lastName}`}</div>
            <div className={styles.seatNumber}>
              {passenger?.seat?.seatNumber}
            </div>
            <div className={styles.priceInfo}>
              &#8377;
              <div>{passenger?.seat?.seatPrice}</div>
            </div>
          </div>
        </PassengerCard>
      )}

      <div className={styles.actionButtons}>
        <button className={styles.skipButton}>
          Proceed without Seat Selection
        </button>
        <button className={styles.proceedButton} onClick={proceedToNext}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default SeatBooking;
