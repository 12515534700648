import React from "react";
// import BaggageIcon from "./BaggageIcon";
import "./ReviewPassengerDetails.css";
import { BaggageClaimIcon, ChevronDown, LuggageIcon } from "lucide-react";
import { useSelector } from "react-redux";
import MobilePassengerCard from "./MobilePassengerCard";
const ReviewPassengerDetails = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const flightBaggage = bookingData.BaggageInformation;
  return (
    <>
      <div className="passenger-review-container">
        <div className="passenger-card">
          <MobilePassengerCard />
          <div className="contact-section">
            <div className="email-row">
              <div className="email-inner-wrapper-review">
                <label htmlFor="emailInput" className="email-label">
                  Email Address
                </label>
                <input
                  type="text"
                  id="confirmationInput"
                  className="confirmation-input"
                  placeholder="Enter the Confirmation"
                  aria-label="Enter the Confirmation"
                />
              </div>
              <div className="review-baggage-info">
                <div className="review-with-baggage-details">
                  <div className="cabin-baggage">
                    <LuggageIcon size={10} />
                    <div>cabin baggage</div>
                  </div>
                  <div type="button" className="baggage-weight">
                    {flightBaggage?.CarryOnBaggage?.WeightLimit}
                  </div>
                </div>
                <div className="review-with-baggage-details">
                  <div className="checkin-baggage">
                    <BaggageClaimIcon size={10} />
                    <div>check-in baggage</div>
                  </div>
                  <div type="button" className="baggage-weight">
                    {flightBaggage?.CheckedBaggage?.Allowance}
                  </div>
                </div>
              </div>
            </div>
            <div className="phone-label">
              Mobile Phone No.
              <div className="phone-section">
                <div className="country-code">
                  <div className="country-code-text">IND +91</div>
                  <ChevronDown />
                </div>
                <input
                  type="tel"
                  id="phoneInput"
                  className="phone-input"
                  aria-label="Phone number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewPassengerDetails;
