import React from "react";
import styles from "./FlightBookingReview.module.css";
import { useSelector } from "react-redux";

const PassengerCard = ({ passengerNumber, name }) => {
  const passengerDetails = useSelector(
    (state) => state.checkoutJourney.bookingData
  ).passengerDetails;
  return (
    <>
      <div className={styles.passengerDetails}>
        <h3>Passenger Details</h3>
        <table>
          <thead>
            <tr>
              <th>Sno.</th>
              <th>Name</th>
              <th>Seat Booking</th>
              <th>Meal & Baggage Preference</th>
            </tr>
          </thead>
          <tbody>
            {passengerDetails.map((passenger, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{`${passenger.firstName} ${passenger.middleName} ${passenger.lastName}`}</td>
                <td>{passenger?.seat?.seatNumber}</td>
                <td>
                  {passenger.meal || passenger.baggage ? (
                    <>
                      {passenger?.meals?.map((item, index) => (
                        <>
                          <span>{item.title}</span>
                          <br />
                        </>
                      ))}
                      {passenger?.baggage.length > 0 ? (
                        <div>
                          {passenger.baggage.reduce(
                            (total, baggage) => total + baggage.weight,
                            0
                          )}{" "}
                          KG
                        </div>
                      ) : (
                        "NA"
                      )}
                    </>
                  ) : (
                    "NA"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.contactDetails}>
        <h3>Contact Details</h3>
        <p>Email Address: {passengerDetails[0]?.email}</p>
        <p>Mobile No.: {passengerDetails[0]?.mobileNo}</p>
      </div>
    </>
  );
};
export default PassengerCard;
