import React from "react";
import { HotelFacility } from "./HotelFacility";
import styles from "./HotelDetails.module.css";
import { Calendar, Dumbbell, Laugh, Pizza, Wifi } from "lucide-react";

const facilities = [
  {
    iconSrc: <Wifi />,
    name: "Free Wi-Fi",
  },
  {
    iconSrc: <Dumbbell />,
    name: "Fitness Center",
  },
  {
    iconSrc: <Pizza />,
    name: "Free Breakfast",
  },
  {
    iconSrc: <Laugh />,
    name: "Kid Friendly",
  },
];

export const HotelDescriptions = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Hotel Description</h1>
      <p className={styles.description}>
        Set in landscaped gardens overlooking the Ébrié lagoon, this upscale
        hotel featuring contemporary local art and architectural touches is 3 km
        from Mosquée de la riviéra and 17 km from Banco National Park.
      </p>
      <div className={styles.contentWrapper}>
        <section className={styles.facilitiesSection}>
          <h2 className={styles.facilitiesTitle}>Hotel Facilities</h2>
          <div className={styles.facilitiesGrid}>
            {facilities.map((facility, index) => (
              <HotelFacility
                key={index}
                iconSrc={facility.iconSrc}
                name={facility.name}
                alt={facility.alt}
              />
            ))}
          </div>
        </section>

        <HotelTimeInfo />
        <div className={styles.roomCard}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0558eb8569b164d1fcc0f85ea1d6698e4df38147f86b7a3d515197f909720a5f?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
            alt="Super Deluxe Room"
            className={styles.roomImage}
          />
          <div className={styles.roomDetails}>
            <h3 className={styles.roomTitle}>
              Super Deluxe Room (without Balcony)
            </h3>
            <div className={styles.roomInfo}>
              <p className={styles.infoText}>No meals included</p>
              <p className={styles.infoText}>Non-Refundable</p>
              <div className={styles.priceSection}>
                <div className={styles.priceWrapper}>
                  <span className={styles.price}>₹ 2,355</span>
                  <span className={styles.perNight}>Per night</span>
                </div>
                <button className={styles.bookButton}>Book Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HotelTimeInfo = () => {
  return (
    <div className={styles.searchInfo}>
      <div className="my-6">
        <p className="pb-9">Your search: 2 Adults, 1 Night</p>
        <div className={styles.checkInOut}>
          <Calendar color="red" className={styles.timeIcon} />

          <span>Checkin 12 PM</span>
        </div>
      </div>

      <Calendar color="red" className={styles.timeIcon} />
      <span>Checkout 11 AM</span>
    </div>
  );
};
