import React, { useRef, useState } from "react";

const SeatMatrixMobile = ({ rows, onSelectingSeat }) => {
  const [showToolTip, setShowTooltip] = useState({});
  const timeOutIdRef = useRef(null);
  const getSeatLabel = (seatIndex, rowIndex, returnType) => {
    let seatLabel;
    switch (seatIndex) {
      case 0:
        seatLabel = "A";
        break;
      case 1:
        seatLabel = "B";
        break;
      case 2:
        seatLabel = "C";
        break;
      case 4:
        seatLabel = "D";
        break;
      case 5:
        seatLabel = "E";
        break;
      case 6:
        seatLabel = "F";
        break;
      default:
        seatLabel = "";
        break;
    }
    const rowLabel = rowIndex;
    const seatNumber = `${rowLabel}${seatLabel}`;
    if (returnType === "seatNumber") return seatNumber;
    else return seatLabel;
  };
  const selectingSeat = (rowIndex, seatIndex, seat) => {
    if (seat.selected === "unavailable")
      alert("seat is not available try with another Seat");
    else {
      const seatNumber = getSeatLabel(seatIndex, rowIndex, "seatNumber");
      //   debugger;
      toolTipShow(seatIndex, rowIndex);
      onSelectingSeat(rowIndex, seatIndex, seatNumber, seat.price);
    }
  };
  const toolTipShow = (seatIndex, rowIndex) => {
    setShowTooltip({ seatIndex, rowIndex });
    if (timeOutIdRef.current) clearTimeout(timeOutIdRef.current);
    timeOutIdRef.current = setTimeout(() => {
      setShowTooltip({});
      timeOutIdRef.current = null;
    }, 3000);
  };
  return (
    <>
      {rows.map((row, rowIndex) => (
        <div className="seat-row" key={`row-${rowIndex}`}>
          {row.map((seat, seatIndex) => (
            <>
              {seat.empty ? (
                <div key={seatIndex} className="seatEmptyLabel">
                  {rowIndex === 0 ? "" : rowIndex}
                </div>
              ) : (
                <>
                  {rowIndex === 0 ? (
                    <div key={seatIndex} className="seatColumsNumber">
                      {getSeatLabel(seatIndex, rowIndex, "seatlabel")}
                    </div>
                  ) : (
                    <div
                      key={seatIndex}
                      className={`seat ${
                        seat.selected === "selected"
                          ? "selected"
                          : !seat.selected
                          ? ""
                          : "not-available"
                      }`}
                      onClick={() => selectingSeat(rowIndex, seatIndex, seat)}
                    >
                      {/* {!seat.selected ? seat.price : ""} */}
                      {showToolTip.seatIndex === seatIndex &&
                        showToolTip.rowIndex === rowIndex && (
                          <div className="seat-tool-tip">
                            &#8377;{seat.price}
                          </div>
                        )}
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        </div>
      ))}
    </>
  );
};

export default SeatMatrixMobile;
