import React from "react";
import { PriceRow } from "./components/PriceRow";
import { RoomService } from "./components/RoomService";
import styles from "./styles/BookingReview.module.css";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import { HotelTimeInfo } from "../hotel-detail/hotel-descriptions/HotelDescriptions";
import { Star } from "lucide-react";

const services = [
  { name: "Free breakfast", isChargeable: false },
  { name: "Free self parking", isChargeable: false },
  { name: "Free Wi-Fi", isChargeable: false },
  { name: "Free breakfast", isChargeable: false },
  { name: "Non refundable", isChargeable: false },
  { name: "Mineral water", isChargeable: true },
  { name: "Heater", isChargeable: true },
];

const priceDetails = [
  { label: "1 room x 2 nights", amount: "1000" },
  { label: "Taxes and fees", amount: "500" },
  { label: "Local tax", amount: "399" },
];

export const BookingReview = () => {
  return (
    <>
      <NavbarNew />
      <div className={styles.container}>
        <main className={styles.content}>
          <h1 className={styles.title}>Review Booking</h1>

          <section className={styles.hotelInfo}>
            <div className={styles.hotelHeader}>
              <h2 className={styles.hotelName}>Sun Park Resort</h2>
              <div className={styles.rating}>
                <Star color="yellow" />
                <span>3.9</span>
              </div>
            </div>
            <button className={styles.reviewsButton}>All Reviews</button>
          </section>

          <section className={styles.roomDetails}>
            <h3 className={styles.roomType}>
              Super Deluxe Room (with Balcony)
            </h3>
            <div className={styles.roomInfo}>
              <span className={styles.roomCount}>1 Room:</span>
              <span className={styles.roomCategory}>Standard Room</span>
            </div>
            <div className={styles.refundPolicy}>Non-Refundable</div>
          </section>

          <HotelTimeInfo />

          <section className={styles.services}>
            <h3 className={styles.servicesTitle}>Room Services</h3>
            <div className={styles.servicesList}>
              {services.map((service, index) => (
                <RoomService
                  key={index}
                  service={service.name}
                  isChargeable={service.isChargeable}
                />
              ))}
              <button className={styles.moreServices}>+ More Services</button>
            </div>
          </section>

          <section className={styles.pricing}>
            <h3 className={styles.pricingTitle}>Price details</h3>
            <hr />
            <br />
            <div className={styles.priceBreakdown}>
              {priceDetails.map((detail, index) => (
                <PriceRow
                  key={index}
                  label={detail.label}
                  amount={detail.amount}
                />
              ))}
            </div>
            <div className={styles.totalAmount}>
              <PriceRow label="Total" amount="2,355" />
            </div>
            <div className={styles.paymentOptions}>
              <PriceRow label="Pay now" amount="3,000" />
              <PriceRow label="Pay at property" amount="1000" />
            </div>
          </section>

          <button className={styles.paymentButton}>Proceed to Pay</button>
        </main>
      </div>
    </>
  );
};
