import { Mail, Phone, Trash2Icon } from "lucide-react";
import React from "react";
import "./PassengerCard.css";
import { useDispatch } from "react-redux";
import {
  deletePassenger,
  setAddPassanger,
  setBaggagePrice,
  setMealPrice,
  setPassengerIndex,
  setSeatPrice,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const PassengerCard = ({ passenger, index }) => {
  const dispatch = useDispatch();
  const editPassenger = () => {
    dispatch(setPassengerIndex(index));
    dispatch(setAddPassanger(true));
  };
  const deletePassengerData = () => {
    const baggagePrice = passenger.baggage
      .map((item) => parseAmount(item.price))
      .reduce((a, b) => a + b, 0);
    const mealPrice = passenger.meals
      .map((item) => item.price)
      .reduce((a, b) => a + b, 0);
    dispatch(setBaggagePrice({ baggagePrice: baggagePrice, Action: "remove" }));
    dispatch(setMealPrice({ mealPrice: mealPrice, Action: "remove" }));
    dispatch(
      setSeatPrice({ seatPrice: passenger.seat.seatPrice, Action: "remove" })
    );
    dispatch(deletePassenger({ passengerId: index }));
  };
  function parseAmount(amount) {
    if (typeof amount === "string") {
      return parseInt(amount.replace(/,/g, "").replace("-", ""), 10) || 0;
    }
    return amount || 0;
  }
  return (
    <>
      <div className="contact-card">
        <div className="header-container">
          <div className="avatar" />
          <div className="username">{`${passenger?.firstName} ${passenger?.middleName} ${passenger?.lastName}`}</div>
          <Trash2Icon size={12} color="#fe6f61" onClick={deletePassengerData} />
          <button className="edit-button" tabIndex="0" onClick={editPassenger}>
            Edit
          </button>
        </div>
        <div className="contact-info">
          <div className="contact-row">
            <Mail color="#fe6f61" size={16} />
            <div className="contact-text">{passenger?.email}</div>
          </div>
          <div className="contact-row">
            <Phone color="#fe6f61" size={16} />
            <div className="contact-text">{passenger?.mobileNo}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PassengerCard;
