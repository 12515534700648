import React from 'react';
import styles from './FlightCard.module.css';
import { FlightTimeInfo } from './FlightTimeInfo';
import { AirlineInfo } from './AirlineInfo';

export const FlightDetailsTab = ({flightDetails}) => {
  return (
    <div className={styles.flightCard}>
      <div className={styles.container}>
              <div className={styles.flightHeader}>
                <AirlineInfo
                  logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/227318a0a5b4920c2c1c2dd48527b163ef0b0b13c7448023e0205a95089301b4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                  airlineName={flightDetails?.Airline?.Name}
                  flightNumber={flightDetails?.Airline?.FlightNumber}
                />
                <div className={styles.routeInfo}>
                  <div className={styles.locationWrapper}>
                    <div className={styles.location}>{flightDetails?.Route?.Departure?.City}</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c7f7a23ea6f311f2fd161b816b0bca550aeb8965e85a3743860fa9ec9151faed?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                      className={styles.routeIcon}
                      alt=""
                    />
                  </div>
                  <div className={styles.destinationInfo}>
                    <div className={styles.destination}>{flightDetails?.Route?.Arrival?.City}</div>
                    <div className={styles.date}>{flightDetails?.Route?.Arrival?.Date}</div>
                  </div>
                </div>
              </div>
        <div className={styles.contentWrapper}>
              <FlightTimeInfo
                date={flightDetails?.Route?.Departure?.Date}
                time={flightDetails?.Route?.Departure?.Time}
                location={flightDetails?.Route?.Departure?.City}
                airport={flightDetails?.Route?.Departure?.Airport}
              />
          <div className={styles.durationColumn}>
            <div className={styles.durationWrapper}>
              <div className={styles.durationLine}>
                <div className={styles.line} />
                <div className={styles.duration}>{flightDetails?.Duration}</div>
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d35131fd8568cb6d61f61ab98edd00f096de686749743bce0ff36a8b5d1845b0?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                className={styles.durationIcon}
                alt=""
              />
            </div>
          </div>
          <div className={styles.arrivalColumn}>
            <div className={styles.arrivalInfo}>
              <div className={styles.arrivalDateTime}>
                <div className={styles.arrivalDate}>{flightDetails?.Route?.Arrival?.Date}</div>
                <div>{flightDetails?.Route?.Arrival?.Time}</div>
              </div>
              <div className={styles.airport}>{flightDetails?.Route?.Arrival?.AirportCode}</div>
              <div>{flightDetails?.Route?.Arrival?.Airport}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};