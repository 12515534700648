import React, { useState } from "react";
import "./Flightdetailsmobile.css";
import FlightRoute from "./FlightRoute";
import BaggageInfo from "./BaggageInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddPassanger,
  setJourneyProgress,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";
import { ArrowRight } from "lucide-react";

const Flightsdetails = () => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const flightData = bookingData.FlightDetails;
  const [price, setPrice] = useState(flightData?.PriceOptions[0]?.price);
  return (
    <>
      <div className="flight-card">
        <div className="flight-details">
          <div className="flight-type">{flightData?.TotalStops}</div>
          <FlightRoute
            departure={flightData?.Route?.Departure}
            arrival={flightData?.Route?.Arrival}
            duration={flightData?.Duration}
          />
          <div className="schedule-info">
            <div className="departure-time">
              <div className="time">{flightData?.Route?.Departure.Time}</div>
              <div className="date">{flightData?.Route?.Departure.Date}</div>
            </div>
            <div className="arrival-time">
              <div className="time">{flightData?.Route?.Arrival?.Time}</div>
              <div className="date">{flightData?.Route?.Arrival.Date}</div>
            </div>
          </div>
          <div className="airline-price">
            <div className="airline-info">
              <img
                src={flightData?.Airline.Logo}
                alt={`${flightData?.Airline.Name} logo`}
                className="airline-logo"
              />
              <div className="airline-name">{flightData?.Airline?.Name}</div>
            </div>
            <button
              className="aeroplane-price"
              onClick={() => {
                dispatch(setJourneyProgress(1));
                dispatch(setAddPassanger(false));
              }}
            >
              {price} <ArrowRight />
            </button>
          </div>
          <div className="divider" />
          <div className="detailed-info">
            <div className="timeline">
              <div className="timeline-line" />
              <div className="timeline-content">
                <div className="timeline-header">
                  <div>{flightData?.Route?.Departure.Time}</div>
                  <div className="airport-name">
                    {flightData?.Route?.Departure?.AirportCode}{" "}
                    {flightData?.Route?.Departure?.Airport}
                  </div>
                </div>
                <div className="timeline-duration">{flightData?.Duration}</div>
                <div className="arrival-details">
                  {flightData?.Route?.Arrival.Time}
                </div>
              </div>
            </div>
            <div className="arrival-date">
              Arrives:{" "}
              {` ${flightData?.Route?.Arrival?.Time} ${flightData?.Route?.Arrival?.Date}`}
            </div>
          </div>
        </div>
        <BaggageInfo
          cabinWeight={
            bookingData?.BaggageInformation?.CarryOnBaggage?.WeightLimit
          }
          checkinWeight={
            bookingData?.BaggageInformation?.CheckedBaggage?.Allowance
          }
        />
      </div>
    </>
  );
};
export default Flightsdetails;
