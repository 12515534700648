import React from "react";
import styles from "./HotelCard.module.css";
import { Link } from "react-router-dom";

export function PriceDisplay({ mainPrice, taxesAndFees }) {
  return (
    <div className={styles.priceContainer}>
      <div className={styles.mainPrice}>
        <div style={{ textDecoration: "line-through" }}>
          ₹{mainPrice.toLocaleString()}
        </div>
      </div>
      <div className={styles.priceDetails}>
        <div className={styles.taxesText}>
          <span className={styles.plusSign}>+</span> {taxesAndFees} taxesss
          &fees/Nights
        </div>
        <Link to={"hotel-listing/undefined"} className={styles.bookButton}>
          ₹{mainPrice.toLocaleString()}
        </Link>
      </div>
    </div>
  );
}
