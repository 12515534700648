import * as React from "react";

const InputField =({ label, value, name, id, type = "text", onChange }) =>{
  return (
    <div className="input-field">
      <div className="input-wrapper">
        <label htmlFor={id} className="input-label">{label}</label>
        <input 
          type={type}
          id={id}
          value={value}
          name={name}
          className="input-value"
          placeholder={label}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
export default InputField;