import React from "react";
import styles from "../styles/FilterButton.module.css";
import { ChevronLeft } from "lucide-react";

export const FilterButton = ({ icon, label }) => {
  return (
    <div className={styles.filterButton}>
      <ChevronLeft />
      <div>{label}</div>
    </div>
  );
};
