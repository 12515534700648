import React, { Fragment, useState } from "react";
import "./FlightsCheapestnBestFilter.css";

const FlightCheapestnBestFilter = () => {
  const [priceOptions, setPriceOptions] = useState([
    { label: "Best", price: "Rs.28,000", duration: "3h 20", active: true },
    { label: "Cheapest", price: "Rs.22,000", duration: "4h 20", active: false },
    { label: "Fastest", price: "Rs.42,000", duration: "2h 20", active: false },
  ]);
  const changePriceOptions = (index) => {
    setPriceOptions((prev) =>
      prev.map((option, i) => ({
        ...option,
        active: i === index,
      }))
    );
  };

  return (
    <div className="price-comparison">
      <div className="comparison-container">
        {priceOptions.map((option, index) => (
          <Fragment key={option.label}>
            <div
              className="price-option"
              onClick={() => changePriceOptions(index)}
            >
              <div>{option?.label}</div>
              <div
                className={`pricecomparison-price ${
                  option?.active ? "active-price" : ""
                }`}
              >
                {option?.price}
              </div>
              <div>{option?.duration}</div>
            </div>
            {index < priceOptions.length - 1 && (
              <div className="vertical-divider" />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default FlightCheapestnBestFilter;
