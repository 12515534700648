import * as React from "react";

const PaymentOptions = ({ icon, title, description }) => {
  return (
    <div className="payment-option">
      <div className="option-content">
        <img
          loading="lazy"
          src={icon}
          alt={`${title} payment option icon`}
          className="option-icon"
        />
        <div className="option-details">
          <div className="option-header">
            <div className="option-title">{title}</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/89393f2821b4185affbd7baf4bda6046bd8f90eecea685fd3e27547b798c71ec?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d"
              alt=""
              className="arrow-icon"
            />
          </div>
          <div className="option-description">{description}</div>
        </div>
      </div>
      <style jsx>{`
        .payment-option {
          display: flex;
          width: 100%;
          max-width: 369px;
          align-items: start;
          gap: 40px 46px;
        }
        .option-content {
          display: flex;
          gap: 40px 42px;
          width: 100%;
        }
        .option-icon {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 35px;
        }
        .option-details {
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: 0;
        }
        .option-header {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
        .option-title {
          color: rgba(34, 49, 63, 1);
          font-size: 16px;
          font-weight: 500;
        }
        .arrow-icon {
          aspect-ratio: 0.58;
          object-fit: contain;
          object-position: center;
          width: 7px;
          align-self: center;
        }
        .option-description {
          color: rgba(118, 118, 118, 1);
          font-size: 10px;
          font-weight: 400;
        }
      `}</style>
    </div>
  );
};
export default PaymentOptions;
