import React from "react";
import orangeFlight from "../../../../../../../Assets/flightspage/orangeflightmobile.svg";
import { useSelector } from "react-redux";

const SeatFlightMobile = () => {
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const departure = bookingData?.FlightDetails?.Route?.Departure;
  const arrival = bookingData?.FlightDetails?.Route?.Arrival;
  return (
    <>
      <div className="route-info-seatmobile">
        <div className="location-info">
          <div className="airport-code">{departure?.AirportCode}</div>
          <div className="city-name">{departure?.City}</div>
        </div>
        <div className="flight-duration">
          <div className="trip-icon">
            <div className="dash-line" />
            <img
              src={orangeFlight}
              alt="Airplane icon"
              className="plane-icon"
            />
            <div className="dash-line" />
          </div>
          <div className="duration-text">
            {bookingData?.FlightDetails?.duration}
          </div>
        </div>
        <div className="location-info">
          <div className="airport-code">{arrival?.AirportCode}</div>
          <div className="city-name">{arrival?.City}</div>
        </div>
      </div>
    </>
  );
};

export default SeatFlightMobile;
