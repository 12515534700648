import React from "react";
import GalleryImage from "./GalleryImage";
import styles from "./Gallery.module.css";

const galleryImages = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b18217b28289cbec686384626fea3ba9722d635a32733d6192f572280fc679df?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
    className: styles.mainImage,
    alt: "Main gallery image",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4a3da6f12a1646d0b853fedcb70dadd27349e770ce1d7baac015cf5b5cb72c7a?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
    className: styles.thumbnailTop,
    alt: "Gallery thumbnail top",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/14b02e8f26aca1cf26c4e8143d0548080e913d12d9aa79d2ee3ee6fda8b3ec3c?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
    className: styles.thumbnailBottom,
    alt: "Gallery thumbnail bottom",
  },
];

function RoomHeader() {
  return (
    <div className={styles.container}>
      <div className={styles.galleryGrid}>
        <div className={styles.mainImageWrapper}>
          <GalleryImage {...galleryImages[0]} />
        </div>
        <div className={styles.thumbnailWrapper}>
          <div className={styles.thumbnailContainer}>
            <GalleryImage {...galleryImages[1]} />
            <GalleryImage {...galleryImages[2]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomHeader;
