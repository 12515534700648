import React, { useState } from "react";
import styles from "./FlightFilter.module.css";
import { FilterSection } from "./FilterSection";

const stopOptions = ["Direct", "1 Stop", "2+ Stops"];
const airlineOptions = ["Air India", "Air India", "Air India", "Air India"];
const travelClassOptions = [
  "Economy",
  "Premium Economy",
  "Business Class",
  "First Class",
];
const refundOptions = ["Refundable", "Non Refundable"];
const specialPreferences = ["Meal Options", "Seat Type"];

export const FlightFilter = () => {
  const [price, setPrice] = useState(5021);

  const handleChange = (event) => {
    setPrice(event.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.filterCard}>
        <FilterSection title="Stops" options={stopOptions} />
        <div className={styles.divider} />

        <div className={styles.filterSection}>
          <div className={styles.sectionTitle}>Journey Duration</div>
          <div
            className={styles.durationSlider}
            tabIndex={0}
            aria-label="Journey Duration"
          />
        </div>
        <div className={styles.divider} />

        <FilterSection title="Airline" options={airlineOptions} />
        <div className={styles.divider} />

        <div className={styles.priceSlider}>
          <label htmlFor="price" className={styles.priceSliderLabel}>
            Price
          </label>
          <div className={styles.sliderContainer}>
            <input
              type="range"
              id="price"
              min="5000"
              max="10000"
              onChange={handleChange}
              value={price}
              className={styles.priceSliderInput}
            />
            <div className={styles.priceText}>
              <span className={styles.minPrice}>₹5,000</span>
              <span className={styles.minPrice}>&#8377;{price}</span>
              <span className={styles.maxPrice}>&#8377;10000</span>
            </div>
          </div>
        </div>
        <div className={styles.divider} />

        <FilterSection title="Travel Class" options={travelClassOptions} />
        <div className={styles.divider} />

        <FilterSection title="" options={refundOptions} />
        <div className={styles.divider} />

        <FilterSection
          title="Special Preferences"
          options={specialPreferences}
        />
      </div>
    </div>
  );
};
