import React from "react";
import "./Baggage.css";
import TrollyBag from "../../../../../../../Assets/flightspage/trollyBag.svg";
import { ChevronLeft } from "lucide-react";
import { useDispatch } from "react-redux";
import { setFareBreakUpsPopUp } from "../../../../../../../features/CheckoutJourney/checkoutJourneySlice";

const baggageOptions = [
  {
    id: 1,
    weight: 3,
    price: "1,200",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/047ff8198993244b255b957d141dafc1d658b5c97dfb767e0aa4a14112602baa?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 2,
    weight: 5,
    price: "2,290",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/cc951bacc6b6f652ca929098eb33cfeeab9397b27410d2b00fb61c321e02cd51?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 3,
    weight: 10,
    price: "4,120",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/3d38f424-bb94-4015-b69a-ea34794880bf?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 4,
    weight: 15,
    price: "6,400",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/9b5d02e8-cfd2-423c-a40a-860e706bc52d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
  {
    id: 5,
    weight: 20,
    price: "8,340",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/d1f31815-84b7-40ca-a76b-7f8640b0ed56?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141",
  },
];

const Baggage = ({
  allBaggages,
  onAddingBaggage,
  onRemovingBaggage,
  backButtonActionToPrev,
}) => {
  const existingBaggage = (baggage) => {
    return allBaggages?.find((m) => m.id === baggage.id);
  };
  const dispatch = useDispatch();
  return (
    <>
      <div className="baggage-container">
        <div className="backBtn-wraper">
          <ChevronLeft onClick={() => backButtonActionToPrev()} />
          <h2 className="selector-title">Add Baggage</h2>
        </div>
        <div className="baggage-options-container">
          <div className="baggage-options-wrapper">
            {baggageOptions.map((baggage, index) => (
              <div className="baggage-option" key={index}>
                <img
                  loading="lazy"
                  src={TrollyBag}
                  className="baggage-icon"
                  alt={`Baggage icon for ${baggage.weight} KG`}
                />
                <div className="baggage-text">
                  Additional {baggage.weight} KG
                </div>
                <div className="baggage-price-section">
                  <div className="baggage-price-wrapper">
                    &#8377;
                    <div>{baggage.price}</div>
                  </div>
                  {existingBaggage(baggage) ? (
                    <button
                      className="baggage-add-button"
                      onClick={() => onRemovingBaggage(baggage)}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="baggage-add-button"
                      onClick={() => onAddingBaggage(baggage)}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="action-buttons">
            <button
              className="fare-button"
              onClick={() => dispatch(setFareBreakUpsPopUp(true))}
            >
              Fare Breakup
            </button>
            <button
              className="review-button"
              onClick={() => backButtonActionToPrev()}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Baggage;
