import { Check, ChevronDown } from "lucide-react";
import React from "react";
import "./PaymentForms.css";

const CreditDebitOption = () => {
  return (
    <>
      <div className="paymentWrapper">
        <div className="headerSection">
          <div className="protectionMessage">
            <div className="securityMessage">
              <Check color="green" size={12} />
              <div className="securityText">We use secure transmission</div>
            </div>
            <div className="securityMessage">
              <Check color="green" size={12} />
              <div className="securityText">
                We Protect your personal information
              </div>
            </div>
          </div>
        </div>
        <label htmlFor="nameOnCard" className="formLabel">
          Name on Card
        </label>
        <input type="text" id="nameOnCard" className="formInput" />

        <label htmlFor="cardNumber" className="formLabel">
          Debit/Credit Card number
        </label>
        <input
          type="number"
          id="cardNumber"
          className="formInput"
          aria-label="label"
        />

        <label className="formLabel">Expiry date</label>
        <div className="dateContainer">
          <div className="monthnyear">
            <div>Month</div>
            <ChevronDown size={24} />
          </div>
          <div className="monthnyear">
            <div>Year</div>
            <ChevronDown size={24} />
          </div>
        </div>

        <div className="country-n-securityWrapper">
          <div className="paymentCountry">
            <label htmlFor="country" className="formLabel">
              Billing country/territory
            </label>
            <div className="countrySelect">
              <div>India</div>
              <ChevronDown size={24} />
            </div>
          </div>
          <div className="securityWrapper">
            <label htmlFor="securityCode" className="formLabel">
              Security Code
            </label>
            <input
              type="number"
              id="securityCode"
              className="securityCodeInput"
              aria-label="Security Code"
            />
          </div>
        </div>
      </div>
      <div className="footer-payment">
        <button className="pay-button">VERIFY & PAY</button>
      </div>
    </>
  );
};

export default CreditDebitOption;
