import React from "react";
import "./UpiOption.css";

const UpiOptions = () => {
  return (
    <>
      <div className="payment-container">
        <div className="scan-pay">
          <h3>Scan and pay</h3>
          <img
            src="https://via.placeholder.com/250"
            alt="QR Code"
            className="qr-code"
          />
        </div>
        <div className="divider-upi" />
        <div className="enter-upi">
          <h3>Enter UPI ID</h3>
          <input
            type="text"
            placeholder="Enter UPI ID here"
            className="upi-input"
          />
        </div>
      </div>
      <div className="footer-payment-upi">
        <span>Total: &#8377;38000</span>
        <button className="upi-pay-button">VERIFY & PAY</button>
      </div>
    </>
  );
};

export default UpiOptions;
