import React, { Fragment, useEffect, useState } from "react";
import "./PassengerForm.css";
import InputField from "./InputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddPassanger,
  setPassengerIndex,
  setBaggagePrice,
  setMealPrice,
  updatePassengerDetails,
  setSeatPrice,
} from "../../../../../../features/CheckoutJourney/checkoutJourneySlice";
import { Phone, PlusIcon } from "lucide-react";
import Baggage from "../BaggageSeatMeal/Baggage/Baggage";
import Meals from "../BaggageSeatMeal/Meal/Meals";
import SeatMobile from "../BaggageSeatMeal/Seat/SeatMobile";
import FacilityItemPrint from "./FacilityItemPrint";

const PassengerForm = () => {
  const dispatch = useDispatch();
  const passengerId = useSelector(
    (state) => state.checkoutJourney.passengerIndex
  );
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const [baggageSeatMeal, setBaggageSeatMeal] = useState("");
  const facilityItems = [
    {
      name: "Baggage",
      description: "Add extra baggage",
      selectedItem: [],
    },
    {
      name: "Seat Selection",
      description: "XXYYZZ",
      selectedItem: {},
    },
    {
      name: "Meal",
      description: "XXYYZZZZ",
      selectedItem: [],
    },
  ];
  const [passenger, setPassenger] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "Male",
    email: "",
    passportNo: "",
    countryCode: "India",
    mobileNo: "",
    saveDetails: false,
    meals: [],
    baggage: [],
    seat: {},
  });

  const [processedData, setProcessedData] = useState(new Set());

  const isNullOrEmpty = (value) => {
    if (Array.isArray(value)) return value.length === 0;
    return !value || value.trim() === "";
  };

  const validatePassengerDetails = () => {
    const fieldsToCheck = ["firstName", "lastName", "email", "mobileNo"];

    for (let field of fieldsToCheck) {
      if (isNullOrEmpty(passenger[field])) {
        alert(`Please fill out the ${field}`);
        return false;
      }
    }
    return true;
  };

  const formFieldChange = (e) => {
    const { name, value } = e.target;
    setPassenger((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addMealToPassenger = (meal) => {
    const selectedMeal = passenger.meals.find(
      (mealItem) => mealItem.quantity > 0
    );
    if (selectedMeal && selectedMeal.id !== meal.id) {
      alert("Remove Previous meal and select new");
      return;
    }
    setPassenger((prevData) => {
      const existingMeal = prevData.meals.find((m) => m.id === meal.id);
      if (existingMeal) {
        return {
          ...prevData,
          meals: prevData.meals.map((m) =>
            m.id === meal.id ? { ...m, quantity: m.quantity + 1 } : m
          ),
        };
      }
      return {
        ...prevData,
        meals: [...prevData.meals, { ...meal, quantity: 1 }],
      };
    });

    dispatch(setMealPrice({ mealPrice: meal.price, Action: "add" }));
  };

  const decreaseMealQuantity = (meal) => {
    setPassenger((prevData) => {
      const mealToDecrease = prevData.meals.find(
        (mealItem) => mealItem.id === meal.id
      );
      if (mealToDecrease && mealToDecrease.quantity > 1) {
        return {
          ...prevData,
          meals: prevData.meals.map((mealItem) =>
            mealItem.id === meal.id
              ? { ...mealItem, quantity: mealItem.quantity - 1 }
              : mealItem
          ),
        };
      }
      return {
        ...prevData,
        meals: prevData.meals.filter((mealItem) => mealItem.id !== meal.id),
      };
    });
    dispatch(setMealPrice({ mealPrice: meal.price, Action: "remove" }));
  };

  const increaseMealQuantity = (meal) => {
    const selectedMeal = passenger.meals.find(
      (mealItem) => mealItem.quantity > 0
    );
    if (selectedMeal && selectedMeal.id === meal.id) {
      alert("Each passenger can only select one meal.");
      return;
    }
    setPassenger((prevData) => {
      return {
        ...prevData,
        meals: prevData.meals.map((mealItem) =>
          mealItem.id === meal.id
            ? { ...mealItem, quantity: mealItem.quantity + 1 }
            : mealItem
        ),
      };
    });

    const mealItem = passenger.meals.find(
      (mealItem) => mealItem.id === meal.id
    );
    if (mealItem) {
      dispatch(setMealPrice({ mealPrice: meal.price, Action: "add" }));
    }
  };

  const addBaggage = (baggage) => {
    setPassenger((prevData) => ({
      ...prevData,
      baggage: [...prevData.baggage, baggage],
    }));
    dispatch(setBaggagePrice({ baggagePrice: baggage.price, Action: "add" }));
  };

  const removeBaggage = (baggage) => {
    setPassenger((prevData) => ({
      ...prevData,
      baggage: prevData.baggage.filter((b) => b.id !== baggage.id),
    }));
    dispatch(
      setBaggagePrice({ baggagePrice: baggage.price, Action: "remove" })
    );
  };

  const settingPassengerSeat = (rowIndex, seatIndex, seatNumber, seatPrice) => {
    if (passenger.seat.seatPrice) {
      dispatch(
        setSeatPrice({ seatPrice: passenger.seat.seatPrice, Action: "remove" })
      );
    }
    setPassenger((prevPassenger) => ({
      ...prevPassenger,
      seat: { rowIndex, seatIndex, seatNumber, seatPrice },
    }));
    dispatch(setSeatPrice({ seatPrice, Action: "add" }));
  };
  const savePassenger = () => {
    if (!validatePassengerDetails()) return;

    const passengerDetailsHash = JSON.stringify({
      firstName: passenger.firstName,
      middleName: passenger.middleName,
      lastName: passenger.lastName,
    });
    if (processedData.has(passengerDetailsHash)) {
      alert("This passenger's details have already been processed!");
      return;
    }
    dispatch(
      updatePassengerDetails({ passengerId, updatedDetails: passenger })
    );

    dispatch(setAddPassanger(false));
    dispatch(setPassengerIndex(null));
    setPassenger({
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "Male",
      email: "",
      passportNo: "",
      countryCode: "India",
      mobileNo: "",
      saveDetails: false,
      meals: [],
      baggage: [],
      seat: {},
    });
  };
  const inputFormFields = [
    { label: "First Name", name: "firstName" },
    {
      label: "Middle Name",
      name: "middleName",
    },
    {
      label: "Last Name",
      name: "lastName",
    },
    {
      label: "Email Address",
      name: "email",
    },
    {
      label: "Passport No.",
      name: "passportNo",
    },
  ];

  useEffect(() => {
    const passengerDetailsHashes = bookingData.passengerDetails.map(
      (passenger) =>
        JSON.stringify({
          firstName: passenger.firstName,
          middleName: passenger.middleName,
          lastName: passenger.lastName,
        })
    );
    setProcessedData((prev) => {
      const updatedSet = new Set(prev);
      passengerDetailsHashes.forEach((hash) => updatedSet.add(hash));
      return updatedSet;
    });
    if (passengerId >= 0) {
      setPassenger(bookingData?.passengerDetails[passengerId] || passenger);
    }
  }, []);
  return (
    <>
      <div className="passenger-form-container">
        <div className="gender-selection">
          <div className="gender-option">
            <label
              className={`${passenger?.gender === "Male" ? "selected" : ""}`}
            >
              <input
                type="radio"
                name="gender"
                value="Male"
                className="hidden"
                checked={passenger?.gender === "Male"}
                onChange={(e) => formFieldChange(e)}
              />
              <span></span>Male
            </label>
          </div>
          <div className="gender-option">
            <label
              className={`${passenger?.gender === "Female" ? "selected" : ""}`}
            >
              <input
                type="radio"
                name="gender"
                value="Female"
                className="hidden"
                checked={passenger?.gender === "Female"}
                onChange={(e) => formFieldChange(e)}
              />
              <span></span>Female
            </label>
          </div>
        </div>
        {inputFormFields?.map((item, index) => (
          <Fragment key={index}>
            <InputField
              label={item.label}
              value={passenger[item.name]}
              id={item.name}
              name={item.name}
              onChange={formFieldChange}
            />
          </Fragment>
        ))}

        <div className="dual-field-container">
          <div className="country-field">
            <div className="country-wrapper">
              <label className="input-label">Country code</label>
              <div className="country-select">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9599d1979db57dc729998b16d614725db370382bbd89e5008d9a17d0b2038bb?placeholderIfAbsent=true&apiKey=9bc514576a37486b886b5f265fda443d"
                  className="country-flag"
                  alt="Country flag"
                />
                <span>(+91)</span>
              </div>
            </div>
          </div>

          <div className="input-field phone-input-field">
            <div className="input-wrapper">
              <label htmlFor="Phone" className="input-label">
                Phone number
              </label>
              <input
                type="phone"
                id="phone"
                value={passenger?.mobileNo}
                name="mobileNo"
                className="input-value"
                placeholder="Phone number"
                onChange={formFieldChange}
              />
            </div>
          </div>
        </div>
        <div className="facility-info">
          <div className="facility-title">Facility</div>
          {facilityItems?.map((item, index) => (
            <div className="facility-item" key={index}>
              <div className="facility-content">
                <div className="facility-name">{item.name}</div>
                <div className="facility-description">
                  <FacilityItemPrint item={item} passenger={passenger} />
                </div>
              </div>
              <div
                className="facility-action"
                onClick={() => setBaggageSeatMeal(item.name)}
              >
                -
              </div>
              <PlusIcon
                className="facility-action"
                onClick={() => setBaggageSeatMeal(item.name)}
              />
            </div>
          ))}
        </div>
        <button className="addPassengerDataBtn" onClick={savePassenger}>
          Add Passenger
        </button>
        <>
          {baggageSeatMeal === "Baggage" && (
            <Baggage
              allBaggages={passenger["baggage"]}
              onAddingBaggage={addBaggage}
              onRemovingBaggage={removeBaggage}
              backButtonActionToPrev={() => setBaggageSeatMeal("")}
            />
          )}
          {baggageSeatMeal === "Seat Selection" && (
            <SeatMobile
              selectedSeat={passenger["seat"]}
              onSettingPassengerSeat={settingPassengerSeat}
              backButtonActionToPrev={() => setBaggageSeatMeal("")}
            />
          )}
          {baggageSeatMeal === "Meal" && (
            <Meals
              allMeals={passenger["meals"]}
              onAddingMeals={addMealToPassenger}
              onDecreaseMeals={decreaseMealQuantity}
              onIncreaseMeals={increaseMealQuantity}
              backButtonActionToPrev={() => setBaggageSeatMeal("")}
            />
          )}
        </>
      </div>
    </>
  );
};
export default PassengerForm;
